
   .GoogleAdminApi{
      background-color: var(--dark-primary);
      position: relative;
      margin-left: -2%;
      margin-top: -3%;
      padding-top: 3%;
      top: -5%;
      left: 0px;
      width: 100vw;
      height: 110vh;
   }
  
  .GoogleAdminApi .cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .GoogleAdminApi .card {
    margin: 40px;
    position: relative;
    height:70vh;
    width:30%;
   
    box-shadow: 0 40px 60px -6px #04090a;
  }
  
  .GoogleAdminApi .card-title {
    display: block;
    text-align: center;
    color: #fff;
    background-color: #026e86;
    padding: 2%;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  
  .GoogleAdminApi .card img {
    width: 100%;
    height: 98%;
    object-fit: cover;
    display: block;
    position: relative;
  }
  
  .GoogleAdminApi .card-desc {
    display: block;
    font-size: 1.2rem;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    padding: 3%;
    opacity: 0;
    /* padding: 18px 8%; */
    background-color: white;
   
    transition: 0.8s ease;
  }

  .GoogleAdminApi .form-control{
    margin: 2%;
    margin-bottom: 4%;
  }
  
  .GoogleAdminApi .card:hover .card-desc {
    opacity: 1;
    height: 100%;
  }
  
  .GoogleAdminApi h1 {
    font-size: 2.8rem;
    color: #fff;
    margin: 40px 0 20px 0;
    text-align: center;
  }
  

  .GoogleAdminApi .bn30 {
    border: 5em;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    -webkit-transform: translate(0);
    transform: translate(0);
    background-image: linear-gradient(45deg, #4568dc, #b06ab3);
    padding: 0.7em 2em;
    border-radius: 65px;
    box-shadow: 1px 1px 10px rgba(255, 255, 255, 0.438);
    -webkit-transition: box-shadow 0.25s;
    transition: box-shadow 0.25s;
    color: white;
  }
  
  .GoogleAdminApi .bn30 .text {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: content-box;
    background-image: linear-gradient(45deg, #4568dc, #b06ab3);
  }
  
  .GoogleAdminApi .bn30:after {
    content: "";
    border-radius: 18px;
    position: absolute;
    margin: 4px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background: #0e0e10;
  }
  
  .GoogleAdminApi .bn30:hover {
    background-image: linear-gradient(-45deg, #4568dc, #b06ab3);
    box-shadow: 0 12px 24px rgba(128, 128, 128, 0.1);
  }
  
 .GoogleAdminApi  .bn30:hover .text {
    background-image: linear-gradient(-45deg, #4568dc, #b06ab3);
  }