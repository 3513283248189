

.createUserForm .form-inline{
    display: flex;
    margin-bottom: 3%;
}

.createUserForm .form-group{
    margin-right: 3%;
}

.createUserForm .form-group input::placeholder{
    font-size: small;
    color: salmon;
}


.createUserForm .form-group input#username::placeholder{
    color: rgb(255, 40, 40);
}

.createUserForm .form-group input:not(#row3){
    width: 30rem;
    
}

.createUserForm .form-group #row3{
    width: 26rem;
}

.createUserForm .form-group select:not(#genderInput){
    width: 30rem;
}

.createUserForm .form-group #genderInput{
    margin-top: 7%;
}

.createUserForm .form-group textarea{
    width: 75vw;
    height: 30vh;
}



.createUserForm label{
    font-weight: bold;
    margin-bottom: 2%;
}

.createUserForm textarea{
    width: 35%;
    height: 8rem;
    resize: none;
    margin-bottom: 3%;
    
}


.createUserForm .btn{
    width: 15rem;
}

.createUserForm button:disabled{
    background-color: lightblue;
    border: none;
    cursor: not-allowed !important;
}