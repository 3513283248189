@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&family=Nunito&display=swap);
:root{
  --primary-color:#006B82;
  --secondary-color:#F27979;
  --dark-primary: #013f4d;
}

.btn-primary{
  background-color: #006B82 !important;
  background-color: var(--primary-color) !important;
  border: none !important;
}

.btn-primary:hover{
  background-color: #013f4d !important;
  background-color: var(--dark-primary) !important;;
}

.pay-btn{
  background-color: teal;
  color: white;
  padding: 1% 2%;
  border: none;
  margin:1.5%;
  width: 10vw;
  margin-top: 5%;
  font-size: 1.2rem;
  font-weight: bold;
}

.pay-btn:hover{
  background-color: rgb(25, 44, 44);
}

.pay-btn:disabled{
  background-color: rgb(155, 155, 155);
  color: rgb(119, 119, 119);
  cursor: not-allowed;
}

.pay-color-panel{
  background-color: #013f4d;
  background-color: var(--dark-primary);
  height: 100vh;
  width: 40vw;
  margin-right: 4%;
  padding: 3%;
}

.pay-color-panel h5{
  color: white;
  font-weight: bold;
  margin-top: 1%;
  font-size: 1.5rem;
  margin-left: 30%;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(243, 254, 255) !important;
  overflow-x: hidden;
  
}

.link-btn{
  color: #1890FF;
}

#create-link{
  color: black;
}

#blue-link-text{
  color: white;

}



#create-link:hover{
  color: #1890FF;
}

.link-btn{
  color: white !important;
}

.back-blue{
  /* background-color: #006B82 !important; */
  background: #006B82;
  background: var(--primary-color);

}

.app{
  height: 100vh;
  padding-bottom: 20%;
}

.site-layout{
  overflow-x: auto;
}


 .yellow {
  color: rgb(255, 187, 0);

}

 .green {
  color: rgb(0, 199, 0);
  
}

.red {
  color: red;
  
}

.youtube-card-group{
  display:flex;
  flex-wrap: wrap;
}

.youtube-card{
  width:30%;
}


.youtube-card p{
  color:gray;
}


.youtube-card h4{
  font-size:1.1rem;
  font-weight:bold;
}




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width:1100px){
  body{
    overflow: auto;
  }
}

.primaryBtn{
  background:#F27979;
  color: white;
  border: none;
  text-decoration: none;
  font-weight: bold;
  padding: 5px 20px;
  margin: 10px;
  border-radius: 5px;
}

.primaryBtn:hover{
  background-color: #e26e6e;
  color: white;
}

.primaryBtn:disabled{
  background-color: lightgray;
  color: gray;
  cursor: not-allowed;
}






:root{
    --primary-color:#006B82;
    --secondary-color:#F27979;
  }
  
  .landingPage #link-hover{
      color: white;
      font-weight: bold;
      font-size: 1.2rem;
      text-decoration: underline;
  }
.landingPage #link-hover:hover{
    color: #F27979;
}
.mt-10{
    margin-top: 10%;
}



.heading , .sub {
    max-width: 80%;
    text-align: left;
    
}

.heading{
    color: white;
    margin-top: -2%;
}

.sub p{
    color: rgb(231, 228, 228) !important;
}



.btn-blue{
    border: 2px solid #F27979;
    border: 2px solid var(--secondary-color);
    padding: 20px;
    color: white;
    width: 300px;
    font-weight: bold;
    text-decoration: none;
    
    
}

.btn-blue:hover{
    color: white;
    text-decoration: none;
    border: none;
    background-color: #F27979;
    background-color: var(--secondary-color);
   
}

.btn-fill{
    padding: 4%;
    color: white;
    width: 20rem;
    font-weight: bold;
    text-decoration: none;
    border: none;
    background-color:#F27979;
    background-color:var(--secondary-color);

}

.btn-fill:hover{
    border: 2px solid #F27979;
    border: 2px solid var(--secondary-color);
    font-weight: bold;
    text-decoration: none;
    color: white;
    background: transparent;

}


.img-section{
    position: relative;
}

.front{
    width: 500px;
    height: 100%;
}

.btn-section{
    margin-top: 7%;
}

.landingPage{
    padding: 0px;
}

.ourServices{
    background-color: white;
    padding: 4% 6%;
    width: 100%;
    margin-top: 0%;
    width: 100vw;
    margin-left: -2%;


}

.ourServices .service-heading{
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600;

}

.ourServices .service-icon{
    width:10vw;
}

.ourServices .service-card{
    height: 65vh;
    margin: 3%;
    width: 25vw;
    box-shadow: 0px 4px 10px lightgray;
}

.ourServices .service-card h5{
    font-family: 'Josefin Sans', sans-serif;

}

.landingPage .read-more-btn{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: transparent;
    border: 2px solid #006B82;
    color: #006B82;
    font-weight: bold;
    padding: 2% 4%;
    margin-top: 5%;
    transition: 250ms ease-in-out;
}

.landingPage .read-more-btn:hover{
    background-color: #006B82;
    color: white;
    cursor:pointer;
}

.ourServices .service-desc{
    margin-top: 5%;
    text-align: justify;
}

.ourServices h3{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.4rem;
    color: rgb(20, 20, 20);
}

.ourServices .servicePara{
    font-size: 1rem;
    text-align: center;
    margin-top: -1%;
    margin-bottom: 4%;
}

.hireUs{
    background-color: white;
    padding: 3% 2%;
    display: flex;
    border-top: 1px solid rgb(240, 239, 239);
    width: 100vw;
    margin-left: -2%;
}

.hireUs .hireUsData{
    max-width: 40vw;
    margin-left: 5%;
    margin-top: 5%;
    text-align: justify;
}

.hireUs .hireUsData h1,.classRoom .classRoomData h1,.bookSession h1,.mentorship .mentorShipData h2{
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600;
}

.hireUs .developerImg{
    width: 40vw;
}

.hireUs .hireUsPara,.classRoom .classRoomData{
    font-size: 1rem;
    text-align: justify;

}



.classRoom{
    background-color: white;
    padding: 3% 5%;
    padding-bottom: 12%;
    width: 100vw;
    margin-left: -2%;
}

.classRoom .classRoomImg img{
    width: 100%;
}

.classRoom .classRoomData{
    margin-top: 4%;
    text-align: center;
}


.bookSession{
    padding: 3% 5%;
    background-color: #004555;
    color: white;
    text-align: center;
    width: 100vw;
    margin-left: -2%;
}

.bookSession h1{
    color: white;
}

.bookSession .bookSessionPara{
    max-width: 40vw;
    font-size: 1rem;
    text-align: center;
    margin-top: -1%;
}

.mentorship,.ourSessions{
    background-color: white;
    padding: 4% 5%;
    width:100vw;
    margin-left: -2%;
}

.mentorship .mentorShipData{
    text-align: center;
}

.mentorship  .mentorShipPara{
    font-size: 1rem;
    margin-top: -1%;
    margin-bottom: 5%;
    max-width: 40vw;
}

.mentorShipCardGroup,.ourSessions .ourSessionsCards{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.mentorShipCardGroup .mentorship-card{
    width: 20vw;
    text-align: center;
    margin-left: 1vw;
    margin-right: 1vw;
}
.mentorShipCardGroup .mentorship-card h5{
    margin-top: 10%;
   font-family: 'Josefin Sans', sans-serif;
}

.mentorShipCardGroup .mentorship-card .mentorshipParaSmall{
    margin-top: -2%;
}

.mentorShipCardGroup .mentorship-card .mentorship-icon{
    width: 10vw;
}

.ourSessions .session-card{
    width:25vw;
    margin-left: 1vw;
    margin-right: 1vw;
    margin-bottom: 2rem;
    box-shadow: 0px 2px 8px rgb(233, 233, 233);

}

.ourSessions .session-desc h5{
    font-family:'Josefin Sans', sans-serif;
    margin-top: 1%;
}

.ourSessions .session-card-bottom{
    display: flex;
    justify-content: space-between;
}

.ourSessions .session-card-bottom button{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.ourSessions .session-card-bottom .session-bottom-item{
    display: flex;
    align-items: center;
    font-size: 1rem;
    width: 30%;
}

.ourSessions .session-card-bottom .session-bottom-item span{
    margin-left: 5%;
}

.ourSessions .ourSessionHeading h2{
    font-family:'Josefin Sans', sans-serif;
}

.ourSessions .sessionPara{
    max-width: 80%;
    margin-top: -2%;
    margin-bottom: 1%;
}

.ourSessions .session-card-icon{
    width: 100%;
}

.ourSessions .session-card-icon:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
    transition: 200ms ease-in;
}

.ourSessions .read-more-btn{
    background-color: #006B82;
    color: white;
    transition: 150ms ease-in;
}

.ourSessions .read-more-btn:hover{
    background-color: #003a47;
    color: white;
    border: 2px solid #003a47;
    cursor: pointer;
}

.contactForm{
    width: 100vw;
    margin-left: -2%;
    padding: 0px;
    padding-top:8%;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
}


.contactForm .contactFormLeft{
    width: 50%;
    background-color: #006B82;
    padding: 2% 6%;
    margin-left: -2%;
    height: 100vh;
}


.contactForm .contactFormLeft h2{
    color: white;
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
}

.contactForm .contactFormLeft .contactFormArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contactForm .contactFormLeft .contactFormArea input{
    border:none;
    border-bottom: 2px solid white;
    display: block;
    background-color: transparent;
    margin-bottom: 6%;
    width: 30vw;
    color: white;
    padding:2% 3%;
}

.contactForm .contactFormLeft .contactFormArea textarea{
    background-color: transparent;
    border:2px solid white;
    color: white;
    width: 65vh;
    margin-top: 4%;
    height: 25vh;
    resize: none;
    overflow-y: auto;
    padding:4%;
}


.contactForm .contactFormLeft .contactFormArea .contactFormBtn{
    padding: 2% 5%;
    background-color: transparent;
    border: 2px solid white;
    color: white;
    font-weight: bold;
    margin-top: 5%;
    margin-bottom: 3%;
}

.contactForm .contactFormLeft .contactFormArea .contactFormBtn:hover{
    background-color: white;
    color: black;
    transition: 200ms ease-in;

}

.contactForm .contactFormLeft .contactFormArea .contactFormBtn:disabled{
    cursor: not-allowed;
}

.contactForm .contactFormRight .contactFormImg{
width: 50vw;
margin-top: -7%;
}




.contactForm .contactFormLeft .contactFormArea input:focus{
    outline: none;
}

.contactForm .contactFormLeft .contactFormArea input::-webkit-input-placeholder{
    color: white;
}

.contactForm .contactFormLeft .contactFormArea input:-ms-input-placeholder{
    color: white;
}

.contactForm .contactFormLeft .contactFormArea input::placeholder{
    color: white;
}


.landingPageFooter{
    background-color: #006B82;
    padding: 7% 6%;
    margin-left: -2%;
    width: 100vw;
    margin-top: -6%;
    height: 100vh;

}

.landingPageFooter .landingFooterLogo{
    width: 10vw;
}

.landingPageFooter .footerContent{
    margin-top: 3%;
    display: flex;
    justify-content: space-between;
}

.landingPageFooter .footerContentArea{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20vw;
    margin-top: 3%;
}

.landingPageFooter .footerContentArea h3{
    color: white;
    font-family: 'Josefin Sans', sans-serif;

} 

.landingPageFooter .footerContentArea a,.landingPageFooter .footerContentArea .footerAddress{
    color: white;
    font-size: 1rem;
    margin-bottom: 4%;
}

.landingPageFooter .footerContentArea a:hover{
    text-decoration: underline;
    font-weight: bold;
}

/* .landingPageFooter .footerContentArea .footerAddress{
    color: white;
    font-size: 1rem;
} */

.landingPageFooter .footerSocialArea{
    display: flex;
    justify-content: center;
    margin-top: 7%;
}

.landingPageFooter .footerSocialArea .footerSocialIcon{
    margin-left: 2%;
    margin-right: 2%;
    background-color: #248297;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
}

.landingPageFooter .footerSocialArea .footerSocialIcon:hover{
    background-color: #02596d;
    transition: 180ms ease-in;
}

.landingPageFooter .footerSocialArea .footerSocialIcon a{
    font-size: 1.5rem;
    color:white;
}


.contactForm .contactFormLeft input.captchaInput{
    width: 17vw;
    margin-left: 5%;
    font-size: 1rem;
    font-weight: bold;
}

 .ant-modal-title{
    font-size: 1.3rem !important;
    font-weight: bold;
}

.landingPage .joinAsBtn,.navbar .joinAsBtn{
    background-color: #F27979;
    color:white;
    font-weight: bold;
    border:none;
    margin-top: 2%;
}

.landingPage .joinAsBtn:hover,.navbar .joinAsBtn:hover{
    background-color: #c76464;
    border:none;
}

@media screen and (max-width:700px) {
    .front{
        width: 80%;
        height: 80%;
        margin-right:10%;
    }
}
.error-img{
    margin-left: 30%;
}

.error-container{
    height: 100vh;
    width: 100vw;
    margin-left: 0;
}

.error-btn{
    background-color: transparent;
    border: 1px solid #006B82;
    color: #006B82;
    border-radius: 50px;
    padding: 1rem;
    margin-left: 45%;
    font-weight: bold;
}

.error-btn:hover{
    background-color: #006B82;
    border: 1px solid transparent;
    color: white;
}
.profile-img-card{
    height: 8rem;
    width: 8rem;
    margin-left: 25%;
    margin-bottom: 2rem;
}

.loginForm .card{
    margin-top: 15%;
    margin-left: 70%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5%;
    
}
 
.eye-icon{
    margin-top: 2%;
    margin-left: 2%;
}

.eye-icon:hover{
    cursor: pointer;
}

.loginForm .input-password:focus{
outline: none !important;
border: none !important;

}

.loginForm .form-control{
    border-radius: 1.5rem;
    margin-bottom: 1.5rem;
}

.loginForm .btn-primary{
    background-color: var(--primary-color);
    border-radius: 2rem;
    border: none;
    width: 16rem;
    font-weight: bold;

}

.login__footer__link{
    display:flex;
    justify-content:space-between;
    margin-top:1rem;
}

.loginWithMobile{
    margin-top:1rem;
    text-align:center;
}

.loginWithMobile a{
    text-decoration:none;
    text-align:center;
    border:2px solid #006B82;
    padding:5px 20px;
    border-radius:100px;
    color:#006B82;
    font-weight:bold;
}

.loginWithMobile a:hover{
    color:white;
    background:#006B82;
}





.profile-img-card{
    height: 8rem;
    width: 8rem;
    margin-left: 25%;
    margin-bottom: 2rem;
}

.registerForm .btn:disabled{
    cursor: not-allowed;
    background: gray !important;
    color: rgb(65, 65, 65);
}

.registerForm .card{
    margin-top: 2%;
    margin-left: 35%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5%;
    

}

.registerForm .form-control{
    border-radius: 1.5rem;
    margin-bottom: 0.8rem;
}

.registerForm .btn-primary{
    background-color: var(--primary-color);
    border-radius: 2rem;
    border: none;
    width: 16rem;
    font-weight: bold;

}



.singleUser .form-inline{
    display: flex;
    margin-bottom: 3%;
}

.singleUser .form-group{
    margin-right: 3%;
}

.singleUser label{
    font-weight: bold;
    margin-bottom: 2%;
}

.singleUser .form-group input,.singleUser .form-group select{
    width: 35vw;
}

.singleUser .form-group input#username::-webkit-input-placeholder{
    color: rgb(255, 40, 40);
}

.singleUser .form-group input#username:-ms-input-placeholder{
    color: rgb(255, 40, 40);
}

.singleUser .form-group input#username::placeholder{
    color: rgb(255, 40, 40);
}

.singleUser textarea{
    width: 75vw;
    height: 30vh;
    resize: none;
    margin-bottom: 3%;
    
}
.singleOrderForm{
    margin-left: 1%;
    
}


.singleOrderForm .form-inline{
    display: flex;
    margin-bottom: 3%;
}

.singleOrderForm .form-group{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 3%;
}

.singleOrderForm .form-group input,.singleOrderForm .form-group select{
    margin-left: 5%;
    
}


.singleOrderForm .form-group input:not(#other){
    width: 30vw;
}






.singleOrderForm .form-group select{
    width: 26vw;

} 

.singleOrderForm label{
    font-weight: bold;
    margin-bottom: 2%;

}




.singleOrderForm .btn{
    width: 15rem;
}

.singleOrderForm button:disabled{
    background-color: lightblue;
    border: none;
    cursor: not-allowed !important;
}

.singleOrderForm input.file{
    width: 50% !important;
}


.singleOrderForm textarea{
    height: 40vh;
}
.createOrderForUser{
    margin-left: 1%;
    
}


.createOrderForUser .form-inline{
    display: flex;
    margin-bottom: 3%;
}

.createOrderForUser .form-group{
    width: 100%;
    margin-bottom: 3%;
}


.createOrderForUser .uploadBtn{
    background-color: teal;
    padding: 5px 10px;
    border: none;
    color:white;
    margin: 5px;
    
}

.createOrderForm .uploadBtn:disabled{
    background-color: rgb(158, 158, 158);
    color: rgb(46, 45, 45);
    cursor: not-allowed;

}

/* .createOrderForUser .form-group input,.createOrderForUser .form-group select{
    margin-left: 5%;
    
} */


.createOrderForUser .form-group input:not(#other){
    width: 35vw;
}






.createOrderForUser .form-group select{
    width: 26vw;
} 

.createOrderForUser label{
    font-weight: bold;
    margin-bottom: 2%;
    width: 25vw;

}




.createOrderForUser .btn{
    width: 15rem;
}

.createOrderForUser textarea{
    height: 35vh;
}

.createOrderForUser button:disabled{
    border: none;
    cursor: not-allowed !important;
}

.createOrderForUser input.file{
    width: 50% !important;
} 
.rating-text{
    font-weight: bold !important;
    text-align: center;
}

.feedback{
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    
}

#orderId{
    font-weight: bold;
}

p{
    color: rgb(32, 32, 32);
    font-style: italic;
}
.rating-background{
    background-color: white;
    width: 40% !important;
    padding: 1%;
    border-radius: 5px;
}



.review{
    margin-top: 3rem;
    
}
.review-box{
    display: flex;
    flex-direction: row;
    width: 80%;
    
    
}
.review-box div:first-child{
    width: 50%;
    height: 100%;
}
.review-box div:last-child{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    

}



.feedback-area{
    width: 60%;
    padding: 2%;
    height: 10rem;
    margin-top: 2%;
}
@media  screen and (max-width:800px) {
    .textareabox{
        width: 90%;
    }
    .orderdetails{
        flex-direction: column;
    }
    .review-box{
        flex-direction: column;
        width: 100%;
    }
    .review-box div:first-child{
        width: 100%;
    }
    .review-box div:last-child{
        width: 100%;
    }
    .feedback{
        padding: 0;
    }
    .text-area{
        width: 100%;
    }
    
    
}
.bookingDetails .card{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 2%;
    box-shadow: 0px 3px 6px lightgray;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.bookingDetails table tr td{
    padding: 2%;
}

.bookingDetails .detailsWidth{
    word-wrap: break-word;
    width: 50vw;
}

.singleSubsciption table tr td{
    padding: 0.8%;
}

.bookingDetails table tr:nth-child(even){
    background-color: rgb(243, 243, 243);
}

/* .bookingDetails .card .details-item{
   display: flex;
   font-size: 1rem;
   margin-top: 2%;
   margin-bottom: 2%;
}

.bookingDetails .card .bookingLabel{
    font-weight: bold;
    margin-right: 10%;
} */
.subscription .subscription-header{
    display: flex;
    justify-content: space-between;
}

.subscription .create-btn{
    width: 20vw;
    font-size: 1rem;
    font-weight: bold;
}

.subscription .subsCardGroup{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


.subscription .subsCardGroup .subsCard{
    width: 25%;
    margin: 1%;
    box-shadow: 0px 5px 8px lightgray;
    border-radius: 5px;
}

.subscription .subsCard .subsCard-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.subscription .subsCard .subsCard-header h5{
    font-size: 2rem;
    font-weight: bold;
}

.subscription .subsCard .subsCard-edit{
    font-size: 1.2rem;
    color: rgb(0, 102, 255);
}


.subscription .subsCard .subsCard-edit:hover{
    color: black;
    cursor: pointer;
}


.subscription .subsCard .subsCard-delete{
    font-size: 1.2rem;
    color: rgb(255, 0, 0);
}


.subscription .subsCard .subsCard-delete:hover{
    color: rgb(117, 1, 1);
    cursor: pointer;
}


.subscription .subsCard .cardActions{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.subscription .subsCard ul{
    list-style-type: none;
    margin-left: -10%;
}

.subscription .subsCard ul li{
    margin-bottom: 3%;
}


.subscription .subsCard-price h6{
    font-weight: bold;
    color: rgb(117, 114, 114);
    margin-top: -2%;
    margin-bottom: 8%;
}

#components-layout-demo-side .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
  
  .site-layout .site-layout-background {
    background: #F0F2F5;
  }

  /* .submenu{
    background-color: var(--dark-primary);
  } */

  /* .ant-menu-item-selected{
    background: var(--secondary-color) !important;
  } */

  
.profile .btn{
    width: 10rem;
}

.profile .btn:hover{
    background: #1890FF;
    border: none;
}

.profile .profileHeaderBadge{
    display: flex;
    align-items: center;
    width:100%;
}

.profile .subscriptionTypeBadge{
    background-color: #007cf0ee;
    color: white;
    font-weight: bold;
    font-size: 0.8rem;
    width: 10vw;
    text-align: center;
    margin-left: 1%;
    margin-top: -1%;
    border-radius: 100px;
    box-shadow: 0px 3px 5px lightgray;
    padding:5px 10px;

}

 .badge{
    background-color: #1890FF;
}

.profile #closeIcon{
    float: right;
    margin: 1%;
    margin-right: -3%;
    font-size: 1.5rem;
    margin-top: -6%;
    color: red;
}

.profile #closeIcon:hover{
    cursor: pointer;
    color:#1890FF;
}

.profile .badge-info{
    background-color: rgb(185, 43, 43);
}

.profile .profileBtnGroup{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    margin-right: 5%;
}

label{
    font-weight: bold;
    font-size: 1rem;
    color: rgb(49, 49, 49);
}
.Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px rgb(0, 0, 0);
    padding: 1.5rem;
    left: 15%;
    top: 20%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
    .Modal {
        width: 500px;
        left: calc(50% - 250px);
    }
}
.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.5);
}
.updateForm .card{
    padding: 5%;
    height: 25rem;
}




.updateForm label{
    margin-bottom: 2%;
    font-weight: bold;
}
.updateForm .form-control{
    margin-bottom: 10%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

}

.updateForm #contact{
    width:25rem ;
}

.updateForm .form-group{
    margin: 1%;
}

.updateForm .form-inline{
    display: flex;
    margin-left: -3%;

}


.updateForm .textarea{
    width: 100%;
    resize: none;
    border: 1px solid rgb(204, 203, 203);
    border-radius: 5px;
    padding: 2%;
    margin-left: 2%;
    margin-top: -4%;
    height: 100%;

}


.updateForm .btn {
    margin-top: 5%;
    margin-left: 30%;
}
.profile-pic-container{
    position: relative;
}
.profile-pic-container .profile-pic{
    width: 8vw;
    height: 8vw;
    object-fit: cover;
    box-shadow: 1px 4px 7px rgb(177, 175, 175);
    border-radius: 100px;

}

.plusBtn{
    border:none !important;
    position: absolute;
    bottom:5%;
    left: 7%;
    z-index: 1;
    font-size: 1.6rem;
    color: whitesmoke;
    background-color: black;
    border-radius: 5px;
    padding: 2px;
}


thead[class*="ant-table-thead"] th{
    font-weight: bold;
  }

#action{
    cursor: pointer;
  }


.createUserForm .form-inline{
    display: flex;
    margin-bottom: 3%;
}

.createUserForm .form-group{
    margin-right: 3%;
}

.createUserForm .form-group input::-webkit-input-placeholder{
    font-size: small;
    color: salmon;
}

.createUserForm .form-group input:-ms-input-placeholder{
    font-size: small;
    color: salmon;
}

.createUserForm .form-group input::placeholder{
    font-size: small;
    color: salmon;
}


.createUserForm .form-group input#username::-webkit-input-placeholder{
    color: rgb(255, 40, 40);
}


.createUserForm .form-group input#username:-ms-input-placeholder{
    color: rgb(255, 40, 40);
}


.createUserForm .form-group input#username::placeholder{
    color: rgb(255, 40, 40);
}

.createUserForm .form-group input:not(#row3){
    width: 30rem;
    
}

.createUserForm .form-group #row3{
    width: 26rem;
}

.createUserForm .form-group select:not(#genderInput){
    width: 30rem;
}

.createUserForm .form-group #genderInput{
    margin-top: 7%;
}

.createUserForm .form-group textarea{
    width: 75vw;
    height: 30vh;
}



.createUserForm label{
    font-weight: bold;
    margin-bottom: 2%;
}

.createUserForm textarea{
    width: 35%;
    height: 8rem;
    resize: none;
    margin-bottom: 3%;
    
}


.createUserForm .btn{
    width: 15rem;
}

.createUserForm button:disabled{
    background-color: lightblue;
    border: none;
    cursor: not-allowed !important;
}
.create-user-main{
    margin-left: 1%;
}







.createOrderForm table{
    width: 80vw;
}


.createOrderForn .greenSuccessText{
    color:green;
}

.createOrderForm .uploadBtn{
    background-color: teal;
    padding: 5px 10px;
    border: none;
    color:white;
    margin: 5px;
    
}

.createOrderForm .uploadBtn:disabled{
    background-color: rgb(158, 158, 158);
    color: rgb(46, 45, 45);
    cursor: not-allowed;

}



.createOrderForm table tr td,.createOrderForm table tr th{
    padding: 1%;
    padding-bottom: 3%;
}


.createOrderForm table tr th{
    width: 17vw;
}

.createOrderForm textarea{
    height: 30vh;
    width: 100%;
    resize: none;
    margin-bottom: 3%;
    
}
.viewOrderDetails{
   display:flex;
   flex-direction: column;
   width:100%;
   background:white;
   border-radius:8px;
   box-shadow:0px 5px 8px lightgray;
   padding:2% 3%

}


.viewOrderDetails .details-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
}


.viewOrderDetails .details-header .title-section{
    flex-basis: 70%;
}

.viewOrderDetails .details-header .progress-section{
    flex-basis: 30%;
}

.viewOrderDetails .details-header .progress-section .progress{
    height: 25px;
    box-shadow: 0px 3px 6px rgb(175, 175, 175);
    background-color: rgb(224, 224, 224);
    
}

.viewOrderDetails .details-header .progress-section .progress .progress-bar{
    padding-top: 10px;
    background-color: #03b36c;
}

.viewOrderDetails .details-header .progress-section .progress h5{
    color: white;
    text-shadow: 0px 3px 5px rgb(121, 121, 121);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
    
}


.viewOrderDetails .details-subsection{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 5%;
    width: 100%;

}

.viewOrderDetails .order-description{
    margin-top: 3%;
}



.viewOrderDetails .order-description,.viewOrderDetails .refund{
    margin-left: 3%;
}

.viewOrderDetails .refund form{
    margin-top: 1%;
}

.viewOrderDetails .order-description #cancellationReason{
color:red;
}

.viewOrderDetails .refund #refundStatus{
    color: rgb(0, 218, 91);
}

.viewOrderDetails .order-description b{
    font-style: normal;
}

.viewOrderDetails .details-subsection .subsection-item{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


.viewOrderDetails .details-subsection .subsection-item div{
    padding:2% 3%;
    display: flex;
    width: 20%;
    justify-content: space-between;
    flex-direction: column;
}


.viewOrderDetails #employeeReporting{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.viewOrderDetails  a.collapse-link{
    display: flex;
    justify-content: space-between;
    padding: 1% 4%;
    border-bottom: 1px solid lightgray;
}

.viewOrderDetails .orderRequirements .card,.viewOrderDetails .deliverables .card,.viewOrderDetails .sourceCode .card {
    background-color: rgb(241, 241, 241);
}

.viewOrderDetails  .uploadArea{
    
    padding:2% 5%;
   
}


.viewOrderDetails .uploadArea img{
    width: 7%;
    margin-bottom: 2%;
}


/* 

.viewOrderDetails table th,.viewOrderDetails table td{
    padding: 1% 2%;
}

.viewOrderDetails table{
    width: 100%;
}
*/

.viewOrderDetails  .red{
    background-color: rgb(209, 53, 53);
    padding: 4px;
    font-weight: bold;
    font-size: 0.8rem;
    border-radius: 100px;
    color: white;
    margin: 0px;
    margin-top: 5px;
    text-align: center;
    width: 15%;
}



.viewOrderDetails  .green{
    background-color: rgb(0, 231, 123);
    padding: 1%;
    font-weight: bold;
    font-size: 0.8rem;
    border-radius: 5px;
    color: rgb(19, 19, 19);
    margin: 2%;
    text-align: center;
    width: 15%;
} 

 .mainViewOrderDetails .msgBadge{
    width: 12px;
    height: 7px;
    padding: 2px 8px;
    background-color: red;
    color: white;
    border-radius: 3px;
}


.viewOrderDetails ul li{
    display: flex;

}

.viewOrderDetails ul li .deleteIcon{
    font-size:1rem;
    color:red;
    margin-left: 20px;
}

.viewOrderDetails ul li .deleteIcon:hover{
    color: rgb(124, 0, 0);
}

.orderDetails__heading__text{
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

 .paginationAreaInOrderDetails{
    margin-top: 15px;
    text-align: center;
}


.paginationAreaInOrderDetails button{
    border: none;
    background-color: transparent;
    color:rgb(0, 140, 255);
    font-size: 17px;
    text-decoration: none;
}

.paginationAreaInOrderDetails button:hover:not(button:disabled){
    text-decoration: underline;
}

.paginationAreaInOrderDetails button:disabled{
    color: gray;
    cursor: not-allowed;
}

.paginationAreaInOrderDetails span{
    color:gray;
    border: 1px solid lightgray;
    padding:5px 8px;
    font-size: 18px;
    margin-left: 8px;
    margin-right: 8px;
}

.proposals__wrapper{
    display:flex;
    justify-content: space-between;
}


.proposals__reviews{
    background-color: white;
    box-shadow: 0px 5px 8px lightgray;
    padding: 1rem;
    border-radius: 10px;
    width:70%;
    margin: 1rem;
}

.proposals__reviews h4{
    text-align: center;
}

.proposals__reviews__container{
    display:flex;
    flex-direction: column;
    align-items: flex-end;
    border:1px solid lightgray;
   height: 45vh;
    background:whitesmoke;
    overflow-y: auto;
}






.proposal__write textarea{
    border: 1px solid lightgray;
    width:90%;
    margin-bottom: 1rem;
    padding: 10px;
    font-weight: bold;
    display: block;

}




.proposals__users{
    background-color: white;
    box-shadow: 0px 5px 8px lightgray;
    padding: 1rem;
    border-radius: 10px;
    width:25%;
    margin: 1rem;
}

.proposals__userItem{
    margin: 10px;
    padding: 10px;
}

.proposals__userItem:hover{
    background-color: whitesmoke;
}

.proposals__userItem img{
    width:50px;
    height: 50px;
    border-radius: 100px;
    object-fit: cover;
    margin-right: 10px;
    margin-bottom: 5px;
}



.proposals__reviews__list{
    margin:10px;

}

.proposals__reviews__msg{
    background:rgb(78, 0, 141);
    padding:10px;
    font-size:0.9rem;
    color:white;
    border-radius:100px;

}

.proposals__review__list__footer{
    margin-top: 5px;
    display: flex;
    align-items: center;
}

.remoteMsgBg{
    background-color: orangered;
}


.leftMsg{
    align-self: flex-start;
}

.proposal__card{
    width:90%;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    background-color: white;
}

.proposal__card__imgWrapper,.reply__proposal__card__imgWrapper{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.proposal__card__imgWrapper img{
    width: 100px;
    height: 100px;
    border-radius: 200px;
    object-fit: cover;
    margin-bottom: 5px;
}

.proposal__cardText{
    width: 70%;
    font-size: 1rem;
    color: rgb(48, 47, 47);
}



.replyTextWrapper span{
    margin:10px;
    font-size:1rem;
    font-weight: bold;
    color:rgb(0, 140, 255);
    cursor:pointer;
}

.proposal__card__Wrapper{
    width:70%;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    margin-bottom:1.2rem;
}

.reply__write__area{
margin: 5%;
}

.reply__write__area textarea{
    width:80%;
    padding:10px;
    border:1px solid lightgray;
}

.reply__write__btns{
    display: flex;
    margin: 2%;
}


.reply__proposal__card{
    margin-left:10%;
    padding: 1rem;
    display: flex;
    width:60%;


}

.reply__proposal__cardText{
    background-color: white;
    padding: 10px;
    width: 100%;

}

.reply__proposal__card__imgWrapper img{
    width: 50px;
    height: 50px;
    border-radius: 200px;
    object-fit: cover;
    margin-bottom: 5px;
}

.replyTextWrapper span:hover{
    color:rgb(3, 94, 168);
}







.commentArea{
    background-color: white;
    padding: 2% 5%;
    display: flex;
    justify-content: center;

}

.commentArea .chatContainer{
background-color: #E6F7FF;
padding: 2% 3%;
width: 90%;

}

.commentArea .chatContainer .messageArea{
    overflow-y: auto;
    margin-bottom: 5%;
    padding: 2% 3%;
    height: 30vh;
    overflow-y: auto;
    

}






.commentArea .chatContainer .messageArea  .request{
    background-color: #01819e;
    padding: 10px 16px;
    color: white;
    border-radius: 100px;
    font-size: 1rem;
    box-shadow: 0px 2px 6px #94bff7;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    
    
}

.commentArea .chatContainer .messageArea  .commentOwn .fileDownloadLink{
    color: white;

}

.commentArea .chatContainer .messageArea  .commentOther .fileDownloadLink{
    color: black;
    
}


.commentArea .chatContainer .messageArea  .response{
    background-color: #ffffff;
    padding: 10px 16px;
    color: rgb(0, 0, 0);
    border-radius: 100px;
    font-size: 1rem;
    box-shadow: 0px 2px 6px lightgray;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;

   
}

.commentArea .chatContainer .messageArea  .order1{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    
   
}

.commentArea .chatContainer .messageArea  .order2{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: flex-end;
   
}

.commentArea .chatContainer .messageArea .commentOther{
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    box-shadow: 0px 2px 6px lightgray;
}

.commentArea .chatContainer .messageArea  .commentOwn{
    background-color: #01819e;
    box-shadow: 0px 2px 6px #94bff7;
    color: white;
}




.commentArea .chatContainer .writeArea{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
   
}

.commentArea .chatContainer .writeArea textarea{
    border: 1px solid lightgray;
    resize: none;
    overflow-y: auto;
    width: 75%;
    border-radius: 100px;
    height: 50px;
    padding:5px 25px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1rem;
}

.commentArea .chatContainer .writeArea .comment-btn{
    background-color: teal;
    border: none;
    font-weight: bold;
    color: white;
    padding:1% 3%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    transition: 0.2s ease-in-out;
}


.commentArea .chatContainer .writeArea .comment-btn:hover{
    background-color: rgb(2, 66, 66);
}

.commentArea .chatContainer .writeArea .comment-btn:disabled{
    background-color: rgb(102, 102, 102);
    font-family: rgb(177, 176, 176);
    font-weight: bold;
    cursor: not-allowed;
}
.assignOrderToEmployee form label{
    font-size: 1.2rem;
    font-weight: bold;
}


.assignOrderToEmployee form  .form-group{
    margin-bottom: 4%;
}


.assignOrderToEmployee form .btn{
    background-color: rgb(13, 71, 71);
    color: white;
    font-weight: bold;
    font-size: 1rem;
    border-radius: 0px;
    border:none;
    padding: 1% 2%;
    

}

.assignOrderToEmployee form .btn:hover{
    background-color: rgb(1, 32, 32);
}
.file-upload-btn{
    width:15vw;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    padding:0.7rem 2rem;
    border-radius:2rem;
    font-family:arial;
    display:flex;
    font-size:1.3rem;
    text-align:center;
    margin-bottom:2%;
    float:right;
    border:1px solid #006B82;
    color: #006B82;
    align-items:center;
    transition: all 0.3s ease-out;
    
}


.file-upload-btn:hover{
    background:#006B82;
    color:white;
    border: 1px solid transparent;
}
.edit-home form{
margin-top: 3%;

}

.edit-home form label{
    font-size: large;
    font-weight: bold;
    margin-bottom: 1%;
}

.edit-home form .form-group{
    margin-top: 3%;
}

.edit-home form textarea{
    height: 30vh;
    resize: none;
    width: 50vw;
}

.edit-home .edit-home-btn{
   background-color: #006B82;
   color: white;
   padding: 1rem;
   font-weight: bold;
   border: none;
   margin-top: 3%;
}

.edit-home-btn:hover{
    background-color: #003844;
}


.order-card{
    width: 45vw;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    box-shadow: 0px 5px 18px rgb(228, 227, 227);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

}


.qty-input{
    margin-left: 5%;
    float: right;
}

.checkbox {
    display: flex;
    justify-content: space-between;
}

.checkbox label b{
    color: rgb(24, 24, 24);
    font-family: -apple-system, BlinkMacSystemFont, 
    'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1rem;
    
}

.partialCheckbox{
    margin-top: 5%;
}


.summary-box{
    width: 30vw;
    margin-left: 10%;
    border-radius: 5px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    box-shadow: 0px 5px 18px rgb(228, 227, 227);
}

.summary-box .line-summary{
    display: flex;
    justify-content: space-between;
    
}

.summary-box .checkout-btn{
    background-color: #006B82;
    padding: 2% 4%;
    color: white;
    font-weight: bold;
    border: none;
    font-size: 1rem;
    width: 100%;
    margin-top: 10%;
    transition: 350ms;

}

.summary-box .checkout-btn:hover{
    cursor: pointer;
    background-color: #023844;
}

.summary-box .checkout-btn:disabled{
    cursor: not-allowed;
    background-color: rgb(190, 190, 190);
    color: rgb(116, 116, 116);
}


.StripeElement {
    height: 40px;
    padding: 10px 12px;
    width: 30vw;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;
  
    box-shadow: 0 1px 3px 0 #e6ebf1;
    transition: box-shadow 150ms ease;
  }
  
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
  
.single-payment-details .card:first-child{
    width:100%;
}
/* .single-payment-details .item-container{
    padding: 1.5% 2.5%;
    display: flex;
    flex-wrap: wrap;
    
}

.single-payment-details .item-container div{
    text-align: center;
    margin-left: 4%;
} */

.single-payment-details .successTag{
    background-color: lightgreen;
    padding: 5px 8px;
    font-size: 14px;
    border-radius: 5px;
    margin-left: 1%;
}


.single-payment-details table th,.single-payment-details table td{
    padding: 1% 2%;
}

.single-payment-details table{
    width: 100%;
}




.createManualPayment h2{
    font-weight: bold;
}

.createManualPayment .form-control{
    
    width: 35vw;
}

.createManualPayment .form-group{
    margin-right: 5%;
    margin-bottom: 2%;
}

.createManualPayment label{
    font-weight: bold;
    margin-bottom: 2%;
    font-size: 1rem;
}
.SendMailToUser{
    padding: 2% 4%;
    background: white;
}

.SendMailToUser .form-group{
    margin-bottom: 3%;
}

.SendMailToUser .form-control{
    width: 40%;
    border-radius: 0px;
}

.SendMailToUser  .write-section{
    border: 1px solid lightgray;
    padding-bottom: 2%;
    width: 75%;
}

.SendMailToUser  .writeBox{
    width: 90%;
    margin-bottom: 2%;
    height: 40vh;
    resize: none;
    padding: 2% 3%;
    border: none;
}

.SendMailToUser textarea:focus{
    outline: none;
}

.SendMailToUser .form-control-select{
    width:40%;
}


.SendMailToUser  .email-send-btn{
    border-radius: 100px;
    margin-left: 2%;
    margin-right: 2%;
    width: 10vw;
    
}

.SendMailToUser  .email-send-btn:disabled{
    background: rgb(155, 155, 155) !important;
    color: rgb(94, 92, 92) !important;
    font-weight: bold !important;
    cursor: not-allowed !important;
}

.SendMailToUser .attachment-icon .fa-paperclip{
    font-size: 1.8rem;

}
.SendMailToUser .attachment-icon:hover{
    cursor: pointer;
}

.SendMailToUser .attached-file-name{
    padding: 1% 2%;
    background: rgb(190, 190, 190);
    color: black;
    font-family: 'Lato' arial;
    font-weight: bold;
    margin-left: 2%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    justify-content: space-between;

}

.SendMailToUser .attached-file-name b{
    width: 100%;
}

.SendMailToUser .attached-file-name .fa-times{
    color: red;
    font-size: 1.6rem;
    
}

.SendMailToUser .attached-file-name .fa-times:hover{
    color: darkred;
    cursor: pointer;
}


.invoiceMaker label {
    font-weight: bold;
    margin-bottom: 2%;
}
.invoiceMaker textarea{
    resize: none;
}
.invoiceMaker input.form-control{
    width: 35vw;
}

.invoiceMaker .order-total{
    background-color: rgb(221, 221, 221);
    padding: 2% 4%;
    width: 35vw;
    
}

.invoiceMaker table{
    width: 100%;
}

.invoiceMaker table td{
    padding-bottom: 2%;
} 


.invoiceMaker .summaryTable{
    background-color: white;
    margin-bottom: 5%;
    box-shadow: 3px 4px 7px rgb(218, 218, 218);
}

.invoiceMaker .summaryTable th{
    border-bottom: 1px solid rgb(226, 224, 224);
    padding: 1%;

}
.invoiceMaker .summaryTable td{
    padding:1%;
}

.invoiceMaker .summaryTable tr:nth-child(even){
    background-color: whitesmoke;
}

/* .invoiceMaker .summaryTable tr:first-child{
    background-color: white;
} */




.mailRecords .composeMailBtn{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 100px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border: none;
    background-color: white;
    box-shadow: 0px 3px 5px lightgray;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    margin-right: 8%;
}


.mailRecords #composePlus{
    width: 20px;
    margin-right: 12px;
    margin-top:-3px;
}

.mailRecords .composeMailBtn:hover{
    background-color: rgb(245, 245, 245);
    border: none;

}

.mailForm form input.form-control{
    margin-bottom: 5% !important;
}


.mailForm .attachment-icon:hover{
    cursor: pointer;
}

.mailForm .attached-file-name{
    padding: 1% 2%;
    background: rgb(190, 190, 190);
    color: black;
    font-family: 'Lato' arial;
    font-weight: bold;
    margin-left: 2%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    justify-content: space-between;

}

.mailForm .attached-file-name b{
    width: 100%;
}

.mailForm .attached-file-name .fa-times{
    color: red;
    font-size: 1.6rem;
    
}

.mailForm .attached-file-name .fa-times:hover{
    color: darkred;
    cursor: pointer;
}

.mailRecords .composeMailWrapper{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 2%;
    
}

.mailRecords .viewMoreMsg,.allFeedback .viewMoreMsg{
    color: rgb(0, 102, 255);
}

.mailRecords .viewMoreMsg:hover,.allFeedback .viewMoreMsg:hover{
    text-decoration: underline;
    cursor: pointer;
}



.DashboardHome{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

.DashboardHome .dashboard-card{
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 3px 6px lightgray;
    padding: 2% 3%;
    width: 50vw;
    margin-left: 3%;
}

.DashboardHome .dashboard-card .Stats{
display: flex;
justify-content: space-around;
flex-wrap: wrap;
}

.DashboardHome .recentOrder{
    margin-top: 3%;
}

.DashboardHome .recentOrder h4{
    font-size: 1rem;
    font-weight: bold;
}

.DashboardHome .dashboard-card .stat-card{
    width: 12vw;
    display: flex;
    height: 27vh;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 1px solid lightgray;
    border-radius: 5px;
    transition: 200ms ease-in-out;
}

.DashboardHome .dashboard-card .stat-card:hover{
    background-color: #40A9FF;
    border-color: #40A9FF;
    color: white;
    cursor: pointer;
}

.DashboardHome .dashboard-card .stat-card .stat-icon{
    font-size: 2rem;
}

.DashboardHome .dashboard-card .stat-card .cart-total{
    margin-top: 6%;
    font-weight: bold;
}

.DashboardHome .dashboard-card .stat-card .total-label{
    font-size: 16px;
}

.DashboardHome .dashboard-card .stat-card .dashboard-card-total{
    background-color: #40A9FF;
    color: white;
    border-radius: 100px;
    margin-top: 7px;
}


/* .DashboardHome .dashboard-card .stat-card:hover  .dashboard-card-total{
    background-color: #40A9FF;

} */




.DashboardHome .user-info-card{
    width: 20vw;
    margin-right: 3%;
    box-shadow: 0px 3px 6px lightgray;
    background-color: white;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
}


.DashboardHome .user-info-card .dashboard-user-img{
    width: 18vw;

}
.DashboardHome .user-info-card .dashboardUserType{
    background-color: rgb(10, 106, 231);
    color: white;
    font-weight: bold;
    padding: 3px 17px;
    border-radius: 100px;
    margin-top: 2%;
}

.dashboard__main  .recommendText{
    font-size: 1.5rem;
    font-weight: bold;
    color:rgb(61, 60, 60);
}

.dashboard__main .service-card-wrapper .service-card{
    margin-left: 0;
}

.dashboard__main  .bookBtn{
    background-color: #006B82;
    color: white;
    transition: 150ms ease-in;
    border: none;
    padding: 4px 8px;
    font-weight: bold;
}

.dashboard__main .bookBtn:hover{
    background-color: #003a47;
    color: white;
    cursor: pointer;
}




.bookingCardsUser{
    background-color: white;
    padding:5% 3%;
    box-shadow: 0px 4px 6px lightgray;
    display: flex;
    margin-bottom: 2%;
    margin-left:3%;
    border-radius: 5px;

}

.bookingCardsUser .singleBookingCard{
    box-shadow: 0px 3px 5px rgb(231, 231, 231);
    margin-left: 5%;
    margin-right:5%;
    padding:8px 10px;
    width: 200px;
    height: 170px;
    text-align: center;
    vertical-align:middle;
}



.bookingCardsUser .singleBookingCard img{
    height:60px;
   margin-top: 10%;

}

.bookingCardsUser .singleBookingCard .dashboard-user-img{
    height: 80px;
}

.bookingCardsUser .singleBookingCard h4{
    font-size: 1.1rem;
    color: rgb(1, 76, 82);
    margin-bottom: 0;
    font-weight: bold;
}

 .bookingCardsUser .user-info-card .dashboardUserType{
    background-color: rgb(10, 106, 231);
    color: white;
    font-weight: bold;
    padding: 3px 17px;
    border-radius: 100px;
    margin-top: 7%;
    margin-left: auto;
    margin-right: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}


.bookingCardsUser  .singleBookingCard:hover{
    box-shadow: 0px 7px 12px lightgray;
    cursor: pointer;
}

.bookingCardsUser  .singleBookingCard small{
    color:rgb(134, 131, 131);
}
.sessionsForBooking form{
    margin-top: 3%;
    
    }
    
    .sessionsForBooking form label{
        font-size: large;
        font-weight: bold;
        margin-bottom: 1%;
    }
    
    .sessionsForBooking form .form-group{
        margin-top: 3%;
    }
    
    .sessionsForBooking form textarea{
        height: 30vh;
        resize: none;
        width: 50vw;
    }
    
    .sessionsForBooking .sessionsForBooking-btn{
       background-color: #006B82;
       color: white;
       padding: 0.7rem;
       font-weight: bold;
       border: none;
       width: 20vw;
       margin-top: 3%;
    }
    
    .sessionsForBooking-btn:hover{
        background-color: #003844;
    }
    
    
.booking .card{
    width:30%;
    box-shadow: 0px 3px 6px lightgray;
    word-wrap: break-word;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 1%;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
}

.booking .card h3{
    margin: 0;
    font-weight: bold;
}

.booking .card h4{
    font-size: 1.2rem;
    color: gray;
    font-weight: bold;
    margin-bottom: 5px;
}

.booking .booking__card__group{
    display: flex;
    flex-wrap: wrap;
}
.userSubscription .subs-header{
    width:100%;
    margin-bottom: 3%;
    margin-top: -3%;
    
}

.userSubscription .subsCardGroup{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

.userSubscription .subsCard{
    width: 25%;
    margin: 1%;
    box-shadow: 0px 5px 8px lightgray;
    border-radius: 5px;
    transition: 0.2s ease-in;
}

.userSubscription .subsCard:hover{
background-color: rgb(247, 245, 245);
}

.userSubscription .subsCard .card-list-details{
    margin-top: 10%;
}

.userSubscription .subsCard  .singleList{
    margin-bottom: 5%;
    display: flex;
}

.userSubscription .subsCard .card-list-details .checkIcon{
    width:15px;
    margin-right: 10px;

}


.userSubscription .subsCard .listData{
    margin-left: 10px;
}



/* .userSubscription .subsCard li::before{
    content:"✔️";
    margin-left: -20px; margin-right: 10px;
} */

.userSubscription .subsCard-price{
    margin-top: 10%;
    text-align: center;
}

.userSubscription .subsCard-price h6{
    font-weight: lighter;
    font-size: 2rem;
}

.userSubscription .perMonth{
    font-size: 1rem;
    font-weight: bold;
    color: rgb(100, 100, 100);
}
.userSubscription .subsCard-btn-area {
    margin-top: 10%;
}


.userSubscription .subsCard-btn-area  .subsCard-btn{
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 80%;
}

.userSubscription .subsCard .subsCard-header{
    text-align: center;
    border-bottom: 3px solid rgb(0, 162, 255);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.userSubscription .subsCard .subsCard-header h5{
    font-weight: bold;
    font-size: 1.7rem;
}

.subscription-input-group{
    display: flex;
    justify-content: space-between;
    margin-top: 4%;

}

.subscription-input-group input{
    margin: 1.5%;
    border-radius: 0px;
    box-shadow: 0px 2px 3px rgb(211, 211, 211);
}

.subscription-payment-form label h6{
    font-weight: bold;
}

.subscription-payment-form .form-group{
    margin-bottom: 5%;
}

.subscription-payment-form textarea{
    border-radius: 0px;
    box-shadow: 0px 2px 3px rgb(211, 211, 211);
    resize: vertical;
}

/* .subscription-payment-form .formGroups{
    display: flex;
    justify-content: space-between;
    flex-direction: row;

}

.subscription-payment-form .form-group,.subscription-payment-form .formGroups{
    margin-bottom: 3%;
}

.subscription-payment-form form label h6{
    font-weight: bold;
}

.subscription-payment-form .formGroups .form-control{
    width: 30vw !important;
} */
.allInvoiceData form .form-group{
    margin-bottom: 2%;
}

.ant-modal{
    margin-top: -3%;
}
.mynotifications .allNotifications{
background-color: white;
box-shadow: 0px 3px 5px rgb(185, 184, 184);
padding:3% 4%;

}

.mynotifications .allNotifications .singleNotification{
    display: flex;
    justify-content: space-between;
    padding: 2% 3%;
    border: 1px solid lightgray;
    margin-bottom: 2%;
    transition: 300ms ease-out;

}

.mynotifications .allNotifications .deleteNotification{
    font-size: 1.5rem;
    margin-left: 2rem;
}

.mynotifications .allNotifications .notify{
    color:rgb(0, 102, 255);
    font-size: 1.5rem;
    
}

.mynotifications .allNotifications .notificationMsg{
    margin-left: 15px;
}

.mynotifications .allNotifications .no-notification{
    width: 7rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.mynotifications .allNotifications .no-notification-wrapper{
   width: 100%;
}

.mynotifications .allNotifications .no-notification-text{
    font-weight: bold;
    color: gray;
    text-align: center;
    margin-top: 2%;

}

.mynotifications .allNotifications .deleteNotification:hover{
    color:rgb(0, 102, 255);
    cursor:pointer;
}

.mynotifications .allNotifications .gray{
    color: gray;
}

.mynotifications .notification-top{
    display: flex;
    justify-content: space-between;
}

.mynotifications .grayText{
    font-weight: bold;
    color: rgb(134, 133, 133);
}

.mynotifications .boldText{
    color: black;
    font-weight: bold;
}

.mynotifications .blueText{
    color:rgb(0, 102, 255);
    font-size: 1.5rem;
    margin-left: 2rem;
}

.mynotifications .notifyGray{
    color:gray;
    font-size: 1.5rem;
}


.bookASession .bookingHeader{
    margin-top: 3%;
}

.bookASession .bookingHeader h5{
    font-weight: bold;
    margin-bottom: 0;
}

.bookASession .bookingHeader .bookingDesc{
    width: 60%;
    color: gray;
    margin-top: 5px;
    margin-bottom: 20px;
}

.bookASession .booking__container{
    background-color: white;
    padding: 10px 15px;
    box-shadow: 0px 4px 6px lightgray;
    width: 60%;
}

.bookASession .booking__container .num{
    background-color: rgb(0, 90, 90);
    width: 20px;
    height: 20px;
    text-align: center;
    font-weight: bold;
    border-radius: 100px;
    color: white;
    margin-right: 2%;
}

.bookASession .booking__container .labelGroup{
    display: flex;
    align-items: center;
}

.bookASession .booking__container .formInputs{
    margin-left: 5%;
}

.bookASession .booking__container .form-group{
    margin-bottom: 3%;
}

.bookASession .booking__container .form-control{
    width: 70%;
    margin-top: 2%;
}

.bookASession .w-70{
    width:70% !important;
}

.bookASession .gray{
    color:gray;

}

.bookASession .text-bold{
    font-weight: bold;
}
.toDoList__header{
    background-color: white;
    height: 25%;
    padding: 10px 15px;
    margin-top: -50px;
    display: flex;
    justify-content: space-between;
}

.toDoList__header .search{
    border-radius: 100px;
    width: 50%;
    border: 1px solid lightgray;
    padding: 4px 10px;
    display: flex;
    align-items: center;
}

.toDoList__header .search input{
    border: 0px;
    background-color: white;
    margin-left: 10px;
    
}

.toDoList__header .search input:focus{
    outline: none;
    
}




.toDoList__header .search .search-icon{
    font-size:  1rem;
}


.toDoList__header .searchArea{
    width: 80%;
}

.toDoTask .controlArea{
    border-top: 1px solid lightgray;
    background: white;
    padding: 10px;
    box-shadow: 0px 4px 8px rgb(238, 237, 237);
}

.toDoTask .controlArea small.btn-control{
    padding: 5px 10px;
    border-radius: 5px;
}

.toDoTask .controlArea small.btn-control:hover{
    background-color: rgb(247, 247, 247);
    cursor: pointer;
}

.toDoSection__wrapper{
    display:flex;
    flex-wrap: wrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding:10px 20px;


}

.toDoSection{
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 5px 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    height: 60vh;
    width: 25vw;
    overflow-y: auto;
}

.toDoSection .toDoSection__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toDoSection__header b{
    font-size: 1.2rem;
}

 .sectionIcon{
    margin-left: 5px;
    margin-right: 5px;
    font-size: 1.2rem;
    padding: 5px;
}

.sectionIcon:hover{
    cursor: pointer;
    background:rgb(221, 221, 221);
    border-radius: 5px;

}

.toDoSection .toDoSection__container{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.toDoSection .addTaskBtn{
    background-color: white;
    padding: 5px 20px;
    border: 1px dashed lightgray;
    transition: 200ms all;


}

.toDoSection .addTaskBtn:hover{
background-color: whitesmoke;

}

.toDoList__addNewTask .formGroup{
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.toDoSection .toDoSection__card{
    background-color: white;
    padding: 5px 15px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    border:1px solid lightgray;
}

.toDoSection .toDoSection__card .cardIcon:hover{
    color: rgb(48, 197, 48);
    
}

.toDoSection .toDoSection__card .cardIconComplete{
    color: rgb(48, 197, 48);
}

.toDoSection .toDoSection__card .icon{
    font-size: 1.2rem;
}

.toDoSection .toDoSection__card .toDoSection__cardHeader{
    display:flex;
    justify-content: space-between;
    align-items:center;
}

.toDoSection .toDoSection__card .card__controls{
margin-top: 10px;
margin-bottom: 10px;
display: flex;
align-items: center;
}

.toDoSection .red{
    color: rgb(240, 0, 0);
    font-weight: bold;
    background-color: white;
    padding: 2px 10px;
    border-radius: 8px;


}

.toDoSection .dueDate{
    margin-left: 10px;
}

.toDoSection .gray{
    color: gray;
}

.CompletedTaskBadge{
    background-color: #2FC22F;

}

.PendingTaskBadge{
    background-color: rgb(250, 87, 87);
}

.viewTaskHeading{
    margin-bottom: 0;
    font-weight: bold;
}

.viewTaskDetails{
    color:gray;
    margin-top: 25px;
}

.viewTaskHeader{
    display: flex;
    justify-content: space-between;

}

.viewTaskDeadline{
    color: rgb(88, 87, 87);
}

.viewTaskDeadlineHead{
    font-weight: bold;

}

.viewTaskDeadlineBody{
    background-color: gray;
}

.toDoSection  .redCard{
    background-color: rgb(168, 23, 23) !important;
    border: 1px solid white !important;
    color: white;
}
.tableProfilePic{
    width :50px;
    height:50px;
    object-fit:cover;
    border-radius: 100px;
    margin-right: 10px;
    margin-left: 10px;
}



.allAttendance .search{
    border-radius: 100px;
    width: 50%;
    border: 1px solid lightgray;
    padding: 7px 10px;
    display: flex;
    align-items: center;
    background-color: white;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.allAttendance .search input{
    border: 0px;
    margin-left: 10px;
    background-color: white;
    width: 100%;
    
    
}

.allAttendance .search input:focus{
    outline: none;
    
}


.allAttendance .searchHeader{
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3%;
}

.allAttendance .searchByDate{
    display: flex;
    flex-direction: column;
}

.allAttendance .searchByDate input{
    border: 1px solid lightgray;
    padding: 5px 10px;
}

.allAttendance .tableProfilePicWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tableProfilePicWrapper .badge{
    margin-top: 8px;
}

.allAttendance .yellow {
    color: rgb(255, 187, 0);

}

.allAttendance .green {
    color: rgb(0, 199, 0);
    
}

.allAttendance .red {
    color: red;
    
}
.attendanceChart__header{
    display:flex;
    justify-content: space-between;
}


.AttendanceChart .chartArea{
width:80%;
}
.userLeave{
    margin-left:5%;
}

.userLeave form{
    width:50%;
    margin-top: 2%;
   
}

.applyLeave .formGroup{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.applyLeave .formGroup label{
    margin-bottom: 10px;
}

 .myLeave .searchHeader{
    display: flex;
    justify-content: space-between;
}
.EmployeeResume_myResumeWrapper__19Wre{
    padding-left: 2%;
}

.EmployeeResume_formGroup__2wPw_{
    display: flex;
    flex-wrap: wrap;
    grid-gap:1rem;
    gap:1rem;
    margin-bottom: 1rem;
    align-items:center;
}
.EmployeeResume_formItem__29nJj{
    width:20rem;
    
}


.EmployeeResume_resumeCardGroup__3uZ6J{
    display:flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.EmployeeResume_resumeCardWrapper__1dkTp{
    display:flex;
    flex-wrap: wrap;
    width:80%;
}

.EmployeeResume_addBtn__1ZYot{
    background: white;
    box-shadow: 0px 3px 5px rgba(116, 115, 115, 0.295);
    padding: 1rem;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width:10rem;
    height:10rem;
    border-radius:5px;
}

.EmployeeResume_addBtn__1ZYot:hover{
    transition: 0.3s;
    border: 1px solid rgb(0, 119, 255);
}

.EmployeeResume_addBtn__icon__29hz_{
    font-size: 4rem;
    color: rgb(0, 119, 255);
    border-radius: 5px;
}

.EmployeeResume_resumeCard__37eG-{
    background: white;
    box-shadow: 0px 3px 5px rgba(116, 115, 115, 0.295);
    padding: 1rem;
    width: 30%;
    text-align:center;
    margin: 1rem;
    margin-top: 0;
    border-radius:5px;

}

.EmployeeResume_myResumeHeader__2dEyB{
    display:flex;
    justify-content:space-between;
    align-items: center;
}



.EmployeeResume_resumeCard__37eG- h4{
    font-size: 1.2rem;
    font-weight: bold;
}

.EmployeeResume_resumeCardIconGroup__xibxL{
    display: flex;
}

.EmployeeResume_resumeCardIcon__3Jj7D{
    color:rgb(0, 119, 255);
    font-size:1.2rem;
    margin: 5px;
  
}

.EmployeeResume_resumeCardIcon__3Jj7D:hover{
   opacity: 0.8;
}

.EmployeeResume_resumeCard__37eG- small{
    color: gray;
}


.EmployeeResume_resumeCollapse__32MY2{
    padding:1rem;
    border:1px solid rgb(182, 180, 180);
    border-radius: 10px;
    background: white;
    width:80%;

}

.EmployeeResume_resumeCollapseHeader__2qzeT{
    display: flex;
    justify-content: space-between;
    
    
}


.EmployeeResume_resumeCollapseHeader__2qzeT h3{
    font-weight: bold;
}

.EmployeeResume_resumeCollapseAddBtn__3fRmP{
    width:2rem;
    height:2rem;
    border: 1px solid rgba(116, 115, 115, 0.295);
    color: black;
    padding: 5px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    margin: 5px;
}

.EmployeeResume_resumeCollapseAddBtn__3fRmP:hover{
    background-color: whitesmoke;
    cursor: pointer;
}

.EmployeeResume_resumeCollapseAddBtnIcon__1E1-N{
    font-size: 1rem;
}

.EmployeeResume_resumeCollapseDetailsWrapper__37jAl{
    margin-top: 0;
}

.EmployeeResume_resumeCollapseDetails__3dwV3{
    margin: 1.5rem 0;
    padding: 1rem;
    border: 1px solid rgb(182, 180, 180);
    border-radius: 10px;


}

.EmployeeResume_resumeCollapseDetails__header__3bIY5{
    display: flex;
    justify-content: space-between;
}

.EmployeeResume_resumeCollapseDetails__header__3bIY5 h5{
    font-weight: bold;
}

.EmployeeResume_resumeCollapseDetails__body__3N2Gx{
    margin-top: 10px;
}

.EmployeeResume_resumeCollapseDetails__body__3N2Gx small{
    color: gray;
}


.EmployeeResume_resumeCollapseDetails__body__3N2Gx p{
    font-style: normal;
}




.AdminEvents_events__header__1gVwB{
    display:flex;
    justify-content: space-between;
}

.AdminEvents_createEvent__form__1RnZS{
    background-color: white;
    margin-top: 3%;
    box-shadow: 0px 0.234px 6px lightgray;
    padding: 1rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.AdminEvents_createEvent__formItem__2P3-f{
   margin: 1rem;
   min-width: 42%;
}


.AdminEvents_createEvent__formItem__2P3-f label{
    display: block;
    margin-bottom: 7px;
}

.AdminEvents_eventFormGroup__2FYMF{
    display: flex;
    flex-wrap: wrap;
}

.AdminEvents_eventDetails__card__2Rl5v{
    background-color: white;
    box-shadow: 0px 0.234px 7px lightgray;
    padding: 1rem;
    border-radius: 5px;
}

.AdminEvents_eventDetails__card__2Rl5v table{
    margin: 2rem;
}

.AdminEvents_eventDetails__card__2Rl5v table td,.AdminEvents_eventDetails__card__2Rl5v table th{
    padding: 5px 10px;
    border: 1px solid lightgray;
}

.AdminEvents_eventDetails__card__2Rl5v table tr:nth-child(even){
    background-color: whitesmoke;
}


.AdminEvents_eventCardImg__26XGq{
    width:100%;
}

.AdminEvents_eventCardImg__26XGq img{
    width: 95%;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 1rem;

}
.RegisterEvent_all__events__3w4Lh{
    padding-top:5%;
}

.RegisterEvent_all__events__3w4Lh h2{
    font-weight: bold;
    text-align: center;
    margin-top: 2rem;
}

.RegisterEvent_all__eventsCard__ksGtX{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}

.RegisterEvent_event__card__1iIA9{
    width:40%;
    margin: 1rem;
    background-color: white;
    box-shadow: 0px 0.234px 8px lightgray;
    border-radius: 5px;
}

.RegisterEvent_event__card__1iIA9 img{
    width: 100%;
}

.RegisterEvent_event__cardBody__1npHR{
    padding: 1rem;
}

.RegisterEvent_event__cardBodyText__3AF9z{
    font-size: 14px;
    color: gray;
}

.RegisterEvent_event__cardBody__1npHR h3{
    font-weight: bold;
}

.RegisterEvent_event__cardFooter__2SDNs{
    padding: 1rem;
    padding-top:0.5rem;
    display: flex;
    justify-content: space-between;
}

.RegisterEvent_RegisterEventForm__19K3C{
    margin-top: 65px;
    position: absolute;
    top:0;
    left:0;
    right:0;
}

.RegisterEvent_registerFormBg___JUwd{
    background-size: cover;
    background-position: center;
    padding: 1rem;
    position: relative;
    top:0;
    left: 0;
    right:0;
    margin:0;
    margin-bottom:2rem;
    min-height: 60vh;
    width: 100%;
}

.RegisterEvent_eventForm__5ZkYm{
    background-color: white;
    padding: 2rem;
    box-shadow: 0px 0.234px 3px rgb(66, 66, 66);
    border-radius: 5px;
    float: right;
    width: 35%;
    position: fixed;
    top: 100px;
    right: 20px;
    box-sizing: border-box;

}

.RegisterEvent_eventForm__5ZkYm h3{
    font-weight: bold;
    border-bottom: 5px solid rgb(223, 223, 223);
    padding-bottom: 10px;

}

.RegisterEvent_eventForm__item__17DCW{
    margin-bottom: 1.2rem;
}

.RegisterEvent_eventForm__itemLink__xI2VY h4{
    font-size:1.1rem;
    font-weight:bold;
    text-align: center;
    margin-top: 10px;
}

.RegisterEvent_eventForm__itemLink__xI2VY h4 a{
    margin-left: 5px;
}

.RegisterEvent_eventForm__item__17DCW > input{
    padding: 15px;
    font-weight: bold;
    margin-bottom: 1rem;
}

.RegisterEvent_eventForm__input__3u4aV{
    margin-bottom: 1rem;
    height: 50px;
    font-weight: bold;
}





.RegisterEvent_eventDetails__2N67V{
    padding: 2rem;
    padding-top: 0;
    width: 60%;
}

.RegisterEvent_eventDetails__2N67V h2{
    font-weight: bold;
}

.RegisterEvent_eventDetailsPara__3FLm6{
   font-size: 0.9rem;
   margin-bottom: 1rem;
   margin-top: 1rem;
}

.RegisterEvent_eventDetailsPara__3FLm6 span{
    margin-right: 1rem;
}
.profile-page{
    margin-left: 0%;
}
.messenger {
  height: calc(100vh - 70px);
  display: flex;
  margin-top: 5%;
}

.chatMenu {
  flex: 3 1;
  border-right: 1px solid rgb(206, 204, 204);
  overflow-y: auto;
}

.chatMenuInput {
  width: 90%;
  padding: 2% 3%;
  border: none;
  padding-left: 7%;
  border-radius: 20px;
  box-shadow: 0px 2px 5px rgb(206, 206, 206);
}
.chatMenuInput::-webkit-input-placeholder{
  font-size: small;
  
}
.chatMenuInput:-ms-input-placeholder{
  font-size: small;
  
}
.chatMenuInput::placeholder{
  font-size: small;
  
}

.searchBtn{
  border-radius: 0 !important;
}

.chatBox {
  flex: 5.5 1;
  padding: 2%;
}

.chatBoxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.chatBoxTop {
  height: 100%;
  overflow-y: scroll;
  padding-right: 10px;
}

.chatBoxBottom {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chatMessageInput {
  width: 80%;
  height: 40px;
  overflow: hidden;
  padding-top: 1%;
  padding-left: 4%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  vertical-align: middle;
  font-size: 1rem;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 50px;
  resize: none;
}

.chatMessageInput:focus{
  outline: none !important;
}

.file-upload-icon{
  font-size: 25px;

}

.file-upload-icon:hover{
  cursor: pointer;
}



.chatSubmitButton {
  width: 70px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid teal;
  color: teal;
  font-weight: bold;
  
}

.chatSubmitButton:hover{
  background-color: teal;
  color: white;
}

.chatOnline {
  flex: 3.5 1;
  overflow-y: auto;
}

.chatMenuWrapper,
.chatBoxWrapper,
.chatOnlineWrapper {
  padding: 10px;
  height: 100%;
}

.noConversationText {
  position: absolute;
  top: 20%;
  left:26%;
}



.noConversationText img{
  width: 8vw;
  margin-left: 30%;

}

.noConversationText h3{
  font-weight: bold;
  margin-top: 2%;
  text-transform: capitalize;
  text-align: center;
}

 #noText{
  font-weight: bold;
  color: rgb(148, 148, 148);
  max-width: 20vw;
  text-align: center;
  padding-left: 10%;
}

@media screen and (max-width: 768px) {
  .chatMenu {
    flex: 1 1;
  }

  .chatMenuInput {
    display: none;
  }

  .chatBox{
    flex: 10 1;
  }

  .chatOnline{
    flex: 1px 1;
  }
}

.conversation {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin-top: 20px;
  
}


.notification-msg{
  margin-left: 5%;
  width:100%;
  color: rgb(139, 139, 139);
}

.badge-message{
  background-color: red;
  color: whitesmoke;
  margin-left: -10%;
  margin-right: 10%;
}

.highlight{
  background-color:lightblue;
}

.conversation:hover {
  background-color: rgb(245, 243, 243);
}

.conversationImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.conversationName {
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .conversationName {
    display: none;
  }
}

.message {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.messageTop{
    display: flex;
}

.msgImage{
    box-shadow: 0px 2px 5px rgb(170, 169, 169);
}

.msgImage:hover{
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    transition: 180ms ease-in-out;
    -webkit-filter: brightness(80%);
            filter: brightness(80%);
   
}


.messageImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.otherFileType{
    padding:1.5% 2%;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    display: flex;
    flex-direction: row;
    background:rgb(236, 235, 235);
    color: black;
    font-weight: bold;
    box-shadow: 0px 2px 6px #b4bfc0;
    border-radius: 5px;

}

.otherOwn{
    background-color: #1877F2;
    box-shadow: 0px 2px 6px #94bff7;
    color: white;
}

.otherOwn a{
    color: white !important;
}

.messageText{
    padding: 10px;
    border-radius: 20px;
    background-color: rgb(245, 241, 241);
    color: black;
    max-width: 300px;
    box-shadow: 0px 2px 6px #b4bfc0;
}

.messageBottom{
    font-size: 12px;
    margin-top: 10px;
}

.message.own{
    align-items: flex-end;
}

.message.own .messageText{
    background-color: #1877f2;
    color: white;
    box-shadow: 0px 2px 6px #94bff7;
   
}
.chat-details-header{
    background-color: #006B82;
    padding:3%;

}

.chat-details-header h3{
    color: white;
    font-weight: bold;
    text-align: center;
}

.details-section{
    background-color: #F6F6F6;
    height: 100%;
}

.details-section .profile-pic{
    display: flex;
    justify-content: center;
}


.details-section .profile-pic img{
    width: 15rem;
    
}

.details-section .info-section h5{
    text-align: center;
    font-weight: bold;
}

.details-section .info-section p{
    text-align: center;
}

.details-section .info-section .project-heading{
    background-color: #006B82;
    padding: 1%;
    margin-top: 5%;
}

.details-section .info-section .project-heading h6{
    font-weight: bold;
    color: white;
    text-align: center;
}

.details-section .profile-pic img{
    width: 14vw;
    height: 14vw;
    object-fit: cover;
    box-shadow: 1px 4px 7px rgb(146, 144, 144);
    border-radius: 100px;
    margin: 5%;
}
.codersarts-chat{
    font-family: 'Josefin Sans', sans-serif;
}


.codersarts-chat .chatBanner{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-top: 2%;
    padding-left: 2%;
    padding-right: 2%;
    border-bottom: 1px solid lightgray;
    padding-bottom: 1%;
    width: 100%;
    background-color: white;
    
}
.codersarts-chat .chatBanner .chatLogo{
    display: flex;
    width:20vw;
}

.codersarts-chat .chatBanner .chatLogo img{
    width: 3vw;
}

.codersarts-chat .return-icon{
    width: 2rem;
    margin-top: 1%;

}

.codersarts-chat .return-icon:hover{
    width: 2.2rem;
    cursor: pointer;
}

.codersarts-chat .chatBanner .codersartsChatLogo{
    margin-left: 5%;
    margin-top:1%;
    display:flex;

}

.codersarts-chat .chatBanner .codersartsChatLogo h5{
    font-weight: bold;
    margin-top: 5%;
    
}

.codersarts-chat .searchArea{
    width: 50vw;
    padding: 1%;
    height: 7vh;
    display: flex;
    align-items: center;
    border: none;
    margin-left: 5%;
    background-color: #F1F3F4;
    border-radius: 5px;

}

.codersarts-chat .searchArea input{
    width: 100%;
    border: none;
    background-color: transparent;
    font-family: 'Nunito', sans-serif;
    margin-left: 3%;
}

/* .codersarts-chat .searchArea input{} */

.codersarts-chat .searchArea input:focus{
    outline: none !important;
}

.codersarts-chat  .searchArea:hover{
    background-color: white;
    box-shadow: 0px 2px 4px lightgray;
    box-shadow: 0px 0.7px 2px lightgray;
    transition: 200ms ease;
}

.codersarts-chat .searchArea .searchIcon{
    font-size: 1.1rem;
    margin-left: 2%;
}

.codersarts-chat .profileArea{
    width: 20vw;
    display: flex;
    align-items: center;
    justify-content: right;

}

.codersarts-chat .profileArea img{
    width: 50px;
    height: 50px;
    border-radius: 100px;
    object-fit: cover;
}

.codersarts-chat .profileArea .chatUserInfo{
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2%;
}

.codersarts-chat .profileArea .chatUserBadge{
    background-color: rgb(0, 140, 255);
    font-weight: bold;
    font-size: 0.8rem;
    margin-top: -2%;
    text-transform: lowercase;
    padding: 1px 2px;
    color: white;
    border-radius: 8px;
    box-shadow: 0px 2px 3px lightblue;
    width: 4rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;

}

.codersarts-chat .chatArea{
    display: flex;
    width: 100%;
    background-color: white;
    height: 100vh;
    position: absolute;
    top: 14vh;
    bottom: 0;
    left: 0;
    right: 0;
    
}

.codersarts-chat .chatSideNav{
    height: 100%;
    width: 27%;
    border-right: 1px solid lightgray;
    padding-top: 2%;
}

.codersarts-chat .chatSideNav .chatLists{
    display: flex;
    flex-direction:column;
    height: 27vh;
    overflow: auto;
    background-color: rgb(250, 248, 248);
}

.codersarts-chat .chatSideNav .singleChatList{
    width: 100%;
    display: flex;
    align-items: center;
    padding:2%;
    padding-left: 5%;
    padding-top: 4%;
    padding-bottom: 4%;
}

.codersarts-chat .chatSideNav .singleChatList:hover{
    background-color: #daf9fc;
    cursor: pointer;
}

.codersarts-chat .chatSideNav .chatLists .activeChat{
    background-color: #daf9fc;
}

.codersarts-chat .chatSideNav .chatCategoryHeading{
    margin-left: 5%;
}

.codersarts-chat .chatSideNav .singleChatList img{
    width: 40px;
    height:40px;
    object-fit: cover;
    border-radius: 100px;
}

.codersarts-chat .chatSideNav .goBack{
    margin-top: 5%;
    margin-left: 5%;
}

.codersarts-chat .chatSideNav .singleChatList h5{
   margin-top: 5%;
   margin-left: 5%;
   font-size: 1rem;
}


[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: auto;
  background: white;
  border: 0px;
  border-radius: 0px;

}



.ant-collapse-borderless{
    background-color: white;
}


.ant-collapse-header{
    font-size: 1.2rem;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    font-size: 1.2rem;
}


.codersarts-chat .chatMessagesWriteArea{
    display: flex;
    flex-direction: column;
    width: 60%;

}





.codersarts-chat .chatMessagesWriteArea .groupChatBoxBottom{
    display: flex;
    padding: 3% 5%;
    align-items: center;
    justify-content: space-between;
    background-color: white;

    
}

.codersarts-chat .chatMessagesWriteArea .groupChatBoxBottom .input-box{
    border: 1px solid rgb(180, 180, 180);
    width: 50vw;
    border-radius: 10px;
    height: 2.5rem;
    resize: none;
    overflow-y: auto;
    padding: 1% 2%;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

}


.codersarts-chat .chatMessagesWriteArea .groupChatBoxBottom .msgSubmit{
    width: 8vw;
    margin-left: 10%;
}

.codersarts-chat .chatMessagesWriteArea .groupChatBoxBottom .attachment{
    margin-left: 3%;
    margin-right: 3%;
}


.codersarts-chat .chatMessagesWriteArea .allChatMessages{
    padding-left: 5%;
    padding-right: 5%;
    height: 70%;
    width: 70vw;
    overflow: auto;
    padding-top: 10%;
    margin-top: 1%;
}


.codersarts-chat  .NoConvImage{
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 8%;
}


.codersarts-chat .NoConvImage img{
    width: 15vw;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.codersarts-chat .noMessages{
    text-align: center;
    display: block;
    margin-top: 10%;
    

}


.codersarts-chat .noMessages img{
    width: 10vw;
}

.codersarts-chat .noMessages h5{
    font-size: 1rem;
    color: rgb(170, 170, 170);
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    margin-top: 5%;
}


.codersarts-chat .currentChatBanner{
    width: 70vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: fixed;
    padding: 1% 2%;
    background-color:#F1F3F4;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 2px lightgray;
    z-index: 100;
}

.codersarts-chat .currentChatBanner img{
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100px;
}


.codersarts-chat .currentChatBanner h6{
    margin-left: 10%;
    width: 100%;
}

.codersarts-chat .currentChatUserBadge{
    background-color: rgb(0, 140, 255);
    font-weight: bold;
    font-size: 0.8rem;
    margin-top: -2%;
    text-transform: lowercase;
    padding: 1px 2px;
    color: white;
    border-radius: 8px;
    box-shadow: 0px 2px 3px lightblue;
    width: 4rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.codersarts-chat .currentChatInfo{
    margin-left: 3%;
}

.codersarts-chat .currentGroupInfo{
    margin-left: 3%;
    text-align: left;
}

.codersarts-chat .groupCreatedAt{
    font-weight: bold;
    color: gray;
}

.codersarts-chat .convInfo{
    margin-left: 5%;
}


.codersarts-chat .deleteChatIcon{
    font-size: 1rem;
    color: red;
    margin-right: 7%;

}

.codersarts-chat .chatNotificationBadge{
background-color: red;
margin-top: 0;
margin-left: -10px;
}

.codersarts-chat .singleChatList .singleChatAllInfo{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.codersarts-chat .homeIcon{
    font-size: 1.2rem;
    color: rgb(48, 47, 47);
}

.codersarts-chat .homeIcon:hover{
    color: #2196F3;
    transition: 180ms ease-in;
}

.groupMsgAvatar{
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 100px;
    margin-right: 10px;

}
.navbar{
    display: flex;
    justify-content: space-between;
    margin-top:0;
    margin-bottom: 1.3%;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    padding-top:10px;
    z-index: 100;
}

.navbar .nav-left-items{
    margin-left:3%;
}


.navbar .notificationCountBadge{
    background-color: red;
    color: white;
    border-radius: 2px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 13px;
    
}




.nav-right-items{
    margin-right: 5%;
    display:flex;
    align-items:center;
}

.nav-right-items .notificationIconWrapper{
    padding-top: 5px;
    margin-left: 10px;

}

.nav-right-items .notificationIconWrapper .notificationIcon{
    font-size: 1.2rem;
    color:white;
}

.ant-dropdown .notificationMenu{
    max-width: 500px;
    background-color: white;
    padding: 10px;
    box-shadow: 0px 3px 6px gray;
}

.ant-dropdown .notificationMenu ul{
    list-style-type: none;
    margin: 0;
    padding:0;
}

.ant-dropdown .notificationMenu ul li{
    margin-bottom: 10px;
    color:black;
    display:flex;
    justify-content: space-between;
    
}

.ant-dropdown .notificationMenu .deleteNotification{
    font-size: 1.5rem;
    margin-left: 2rem;
}
.ant-dropdown .notificationMenu .deleteNotification:hover{
    color:red;
    cursor:pointer;
}

.ant-dropdown .notificationMenu .notificationLink{
    width: 100%;
    margin-left: 45%;
}

.ant-dropdown .notificationMenu .notify{
    margin-right: 5px;
    margin-top: 6px;
}


.ant-dropdown .notificationMenu ul li small{
    color:gray;
    display: block;
}


.profile-avatar{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}



.profile-items{
    display: flex;
    width:  3rem;
    
}



.coinArea{
    margin-left: 10px;
    display: flex;
    align-items: center;

}

.coinArea img{
    width:25px;
    margin-right: 10px;

}

.coinArea span{
    font-weight: bold;
    color: white;
}


.publicPayment .greenColorPallete{
    background-color: #006B82;
    width: 55vw;
    padding: 5% 8%;
    margin-left: -5%;
    height: 150vh;
    display: flex;
    
}

.publicPayment .paymentItems{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.publicPayment .greenColorPallete h4{
    color: white;
    font-family: 'Lato', sans-serif;
    text-align: left;
    font-weight: 700;
    font-size: 2rem;
}

.publicPayment .greenColorPallete .termsAndCondition{
    width:100%;
}

.publicPayment .terms-link{
    color: lightcoral;
    text-decoration: underline;
}

.publicPayment .terms-link:hover{
    color: white;
    
}

.publicPayment .contact-details-section{
    display: flex;
    flex-direction: column;
}

.publicPayment .contact-details-section div{
    margin-right: 2%;
    width: 50%;
}

.publicPayment .contact-item{
    color:white;
    margin-top:4%;
    display:flex;
    width:100%;
    justify-content:space-between;
}

.form-section{
    padding-top: 8%;
    padding-left: 5%;
}
.form-section .form-control{
    font-family: 'Lato', sans-serif;
    width: 35vw;
    box-shadow: 0px 1px 3px lightgray;
}

.form-section .form-group{
    margin-bottom: 3%;
    margin-right: 5%;
}

.form-section label{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    margin-bottom: 2%;
}

.payment-logo-area{
    margin-left: 15vw;
}
.coderartsLogo {
    width: 250px;
    height: 250px;

}

.publicPayment .payment-heading{
    display: none;
}

.publicPayment #country-code-link{
    margin-left: 60%;
    text-align: right;
}


@media screen and (max-width:600px){
    .publicPayment .d-flex{
        display: flex;
        width: 100vw;
        flex-wrap: wrap;
        flex-direction: column;
       
    }

    .publicPayment .payment-heading{
        display: block;
        color: rgb(0, 63, 63);
        font-family: 'Lato', sans-serif;
        text-align: left;
        font-weight: 700;
        font-size: 1.8rem;
        margin-left: -3%;
        border-bottom: 4px solid rgb(0, 63, 63);
        width: 86vw;
        padding-bottom: 3%;
    }

    .publicPayment .green-heading{
        display: none;
    }

    

    .publicPayment .paymentItems {
        display: flex;
        flex-direction: column;
    }

    .publicPayment .paymentItems div:first-child{
        order: 1;
    }

    .publicPayment .form-group{
        width: 100vw;
        margin-left: -3%;
    }

    .publicPayment .termsAndCondition{
        max-width: 90%;
    }

    .publicPayment .payment-logo-area{
        margin-left: 1%;
        margin-top: 2%;
        margin-bottom: 5%;
    }

    .form-section .form-control,.form-section .form-control[placeholder="address"]{
        width: 86vw !important;
    }

    .publicPayment .StripeElement{
        width: 86vw;
        
    }

    .publicPayment #country-code-link{
        margin-left: 25%;
    }

    .publicPayment .pay-btn{
        margin-left: 25%;
        margin-bottom: 10%;
    }
    .publicPayment .greenColorPallete div{
        width: 100vw;
        
    }

   
   
    .publicPayment .greenColorPallete{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 105vw;
    }
}






   .GoogleAdminApi{
      background-color: var(--dark-primary);
      position: relative;
      margin-left: -2%;
      margin-top: -3%;
      padding-top: 3%;
      top: -5%;
      left: 0px;
      width: 100vw;
      height: 110vh;
   }
  
  .GoogleAdminApi .cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .GoogleAdminApi .card {
    margin: 40px;
    position: relative;
    height:70vh;
    width:30%;
   
    box-shadow: 0 40px 60px -6px #04090a;
  }
  
  .GoogleAdminApi .card-title {
    display: block;
    text-align: center;
    color: #fff;
    background-color: #026e86;
    padding: 2%;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  
  .GoogleAdminApi .card img {
    width: 100%;
    height: 98%;
    object-fit: cover;
    display: block;
    position: relative;
  }
  
  .GoogleAdminApi .card-desc {
    display: block;
    font-size: 1.2rem;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    padding: 3%;
    opacity: 0;
    /* padding: 18px 8%; */
    background-color: white;
   
    transition: 0.8s ease;
  }

  .GoogleAdminApi .form-control{
    margin: 2%;
    margin-bottom: 4%;
  }
  
  .GoogleAdminApi .card:hover .card-desc {
    opacity: 1;
    height: 100%;
  }
  
  .GoogleAdminApi h1 {
    font-size: 2.8rem;
    color: #fff;
    margin: 40px 0 20px 0;
    text-align: center;
  }
  

  .GoogleAdminApi .bn30 {
    border: 5em;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    -webkit-transform: translate(0);
    transform: translate(0);
    background-image: linear-gradient(45deg, #4568dc, #b06ab3);
    padding: 0.7em 2em;
    border-radius: 65px;
    box-shadow: 1px 1px 10px rgba(255, 255, 255, 0.438);
    transition: box-shadow 0.25s;
    color: white;
  }
  
  .GoogleAdminApi .bn30 .text {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: content-box;
    background-image: linear-gradient(45deg, #4568dc, #b06ab3);
  }
  
  .GoogleAdminApi .bn30:after {
    content: "";
    border-radius: 18px;
    position: absolute;
    margin: 4px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background: #0e0e10;
  }
  
  .GoogleAdminApi .bn30:hover {
    background-image: linear-gradient(-45deg, #4568dc, #b06ab3);
    box-shadow: 0 12px 24px rgba(128, 128, 128, 0.1);
  }
  
 .GoogleAdminApi  .bn30:hover .text {
    background-image: linear-gradient(-45deg, #4568dc, #b06ab3);
  }
.fileUploadComponent{
    display: flex;

}

.fileUploadComponent .upload-vector{
    width: 40vw;
    height: auto;
    margin-top: 5%;


}

.fileUploadComponent .upload-icon{
    width: 6rem;
}

.fileUploadComponent .upload-heading{
    margin-top: 5%;
    margin-left: 15%;
    text-align: center;
    font-family: 'Lato';
    font-weight: 600;
}

.fileUploadComponent .form-group{
    margin-left: 8%;
    margin-bottom: 5%;
}

.fileUploadComponent  label{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    margin-bottom: 2%;
}

.fileUploadComponent .form-control{
    font-family: 'Lato', sans-serif;
    width: 40vw;
    box-shadow: 0px 1px 3px lightgray;
}
.groupConversation{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    background-color: white;
    
}

.groupConversation .allGroups{
    flex: 2 1;
    padding: 1%;
    height: 100vh;
    background-color: white;
    margin-left: 0px;
} 

.groupConversation .deleteGroupIcon{
    float: right;
    font-size: 1.2rem;
    cursor: pointer;
}

.groupConversation .deleteGroupIcon:hover{

    cursor: pointer;
    
}

.groupConversation .groupChatBox{
    flex: 4.5 1;
    padding-top: 5%;
    margin-bottom: -5%;
}


.groupConversation .groupChatBox .groupChatBoxWrapper{
    height: 90%;
    display: flex;
    flex-direction: column;
}

.groupConversation .groupChatBox .groupChatBoxWrapper .groupChatBoxTop{
    padding: 4% 4%;
    background-color: whitesmoke;
    height: 27rem;
    margin-top: -2%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.groupConversation .singleGroupMsg{
    margin-bottom: 3%;

}

/* .groupConversation .singleGroupMsg .msgBottom{
    margin-top: 5%;
    width: 15rem;
} */

.groupConversation .highlight{
    background-color: rgb(240, 240, 240);
}

.groupConversation .groupChatBox .groupChatBoxWrapper .groupChatBoxBottom{
    display: flex;
    padding: 3% 5%;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    
}

.groupConversation .groupChatBox .groupChatBoxWrapper .groupChatBoxBottom .input-box{
    border: 1px solid rgb(180, 180, 180);
    width: 35rem;
    border-radius: 10px;
    height: 2.5rem;
    resize: none;
    overflow-y: auto;
    padding: 1% 2%;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

}



.groupConversation .noConversationText{
    width: 100%;
}



.groupConversation .noConversationText h3{
    margin-left: -30%;
}

.groupConversation .noConversationText #noText{
    margin-left: 20%;
}


.groupConversation .group-header{
    margin-top: 20%;

}

.groupConversation .group-header h3{
    margin-top: 3%;
}

.groupConversation .group-header .groupHeaderControls {
    display: flex;
    justify-content: space-between;
}


.groupConversation .group-header .groupHeaderControls .addGroup{
    margin-top: 4%;
}

.groupConversation .groupSideBody{
    margin-top: 2%;

}

.groupConversation .groupSideBody .singleGroup{
    margin-bottom: 3%;
    padding: 2% 3%;


}

.groupConversation .groupSideBody .singleGroup:hover{
    background-color: rgb(240, 240, 240);
   cursor: pointer;
}

.groupConversation .groupSideBody .singleGroup img.groupIcon{
    width: 40px;
    height:40px;
    border-radius: 100px;
    border: 1px solid lightgray;
    padding:5px;
}

.groupConversation .groupSideBody .singleGroup .groupName{
    margin-left:3%;
    font-size: 1rem;
    font-weight: bold;
}
.groupMembers{
    padding: 5px;
    padding-top: 5%;
    background-color: white;
    flex: 1.5 1;
    
}

.groupMembers .singleMember{
    padding: 2px 3px ;
    margin-bottom: 3%;

}


.groupMembers .singleMember img{
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 100px;
    margin-right: 6%;
    border: 1px solid lightgray;
}
.createOrderPublic{
    margin-left: 10%;
    margin-right: 10%;
    margin-top:7%;
    
}

.createOrderPublic h3{
    color: black;
}


.createOrderPublic .form-inline{
    display: flex;
    margin-bottom: 1.5%;
}

.createOrderPublic .form-group{
    width: 100%;
    margin-bottom: 1.5%;
}

/* .createOrderPublic .form-group input,.createOrderPublic .form-group select{
    margin-left: 5%;
    
} */


.createOrderPublic .form-group input:not(#other){
    width: 35vw;
}






.createOrderPublic .form-group select{
    width: 26vw;
} 

.createOrderPublic label{
    font-weight: bold;
    margin-bottom: 1%;
    width: 25vw;

}




.createOrderPublic .btn{
    width: 15rem;
}

.createOrderPublic textarea{
    width: 96%;
    height: 35vh;
}

.createOrderPublic button:disabled{
    border: none;
    cursor: not-allowed !important;
}

.createOrderPublic input.file{
    width: 50% !important;
} 


.createOrderPublic #categorySelect{
    width: 35vw;
}

.createOrderPublic .uploadMultipleMsg{
    width: 50%;
}

.createOrderPublic .uploadMultipleMsg p{
    color: rgb(221, 1, 1);
}

.createOrderPublic .requiredStar,.createOrderPublic .error{
    color:red;
}

.createOrderPublic .errorField{
    border: 1px solid red;
}
.forgetPassword input{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
}

.forgetPassword .card{
    margin-left: auto;
    margin-right: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: auto;
}

.forgetPassword .form-group{
    margin-left: auto;
    margin-right: auto;
}

.forgetPassword h1{
    margin-top: 7%;
    margin-bottom: 2%;
    font-size: 1.5rem;
    text-align: center;
    font-weight: bold;
}

.forgetPassword .btn-primary{
    height: 40px;
}
.forgetPassword .btn-primary:disabled{
    background-color: rgb(194, 193, 193) !important;
    color: rgb(97, 96, 96);
    cursor: not-allowed;
}

.forgetPassword .error{
    text-align: center;
    margin-top: -20px;
    margin-bottom:20px;
    padding-top: 0;
}
.forgetPassword .error small{
    color:red;

   
}
.forgetPassword .errorField{
    border:1px solid red;
    color:red;
}
.whatsappWrapper{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    margin-left: -10px;

    

}

.whatsappWrapper .chatBanner{
    position: static;
}

.whatsappChatAreaWrapper{
    display: flex;
    height: 100%;
    overflow: auto;
}

.whatsappChats{
    width:30%;
    padding: 1rem;
    background-color: white;
    border-right: 1px solid lightgray;
    box-sizing: border-box;
    overflow: auto;
}


.whatsappMsgArea{
    width: 60%;
    background-color: rgb(253, 252, 252);
    border-right: 1px solid lightgray;
    box-sizing: border-box;
}

.whatsappMsgAreaIcon{
    color:white;
    font-size:1.3rem;
    margin:5px;
}

.whatsappControlIcon{
    font-size:2rem;
    margin: 1rem;
}

.whatsappControlIcon:hover{
    color:#1da1f2;


}

.whatsappChat__userWrapper{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content: flex-start;
   
}

.whatsappChat__user{
    display: flex;
    width:100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
   
    padding: 10px 15px;
}

.whatsappChat__user b{
    font-size: 1.2rem;
    font-family: 'Calibri';

}

.whatsappChat__user small{
    color:gray;

}


.whatsappChat__user:hover{
    background-color: whitesmoke;
    cursor: pointer;
}

.activeChatUser{
    background-color: whitesmoke;
}




.whatsappMsgControls{
    width:10%;
    display: flex;
    background-color: white;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.whatsappChatImgWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10%;

}

.whatsappChatBanner{
    background-color:#1da1f2;
    width:100%;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.whatsappChatBanner h4{
    font-size:1.2rem;
    color:white;
    margin:0;
}

.whatsappChatBanner p{
    color:whitesmoke;
    margin:0;
    margin-top: 5px;
}

.whatsappChatImgWrapper img{
    width:15rem;

}

.whatsappChatImgWrapper h4{
    color:gray;
    font-size: 1.5rem;
    font-family: 'Calibri';
    margin-top: 1rem;
}

.whatsappChatBannerWrapper{
    display:flex;
    flex-direction:column;
    height:90%;
    justify-content:space-between;

}

.whatsappSendArea{
    margin: 1rem;
}

.whatsapp__msgArea{
    height:50vh;
    overflow:auto;
    width:100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.whatsapp__chatText{
    width:250px;
    border-radius: 1rem;
    box-shadow: 0px 2px 4px lightgray;
    padding: 10px;
    margin:15px;
    margin-right: 0px;
    margin-bottom: 5px;
    border: 1px solid rgb(121, 118, 118);
    
}

.whatsapp__chatTextReceived{
    width:250px;
    border-radius: 1rem;
    box-shadow: 0px 2px 4px lightgray;
    padding: 10px;
    margin:15px;
    margin-left: 0px;
    margin-bottom: 5px;
   background: rgb(26, 45, 216);
   color:white;

}
.whatsapp__singleChatRight{
    align-self: flex-end;
    margin-bottom: 15px;
}

.whatsapp__singleChatLeft{
    margin-bottom: 15px;
}

.whatsapp__singleChatRight small, .whatsapp__singleChatLeft small{
    color:gray;
}
.whatsapp__chatTemplate{
    width:250px;
    margin:10px;
    margin-left: 0px;
    margin-bottom: 5px;
    border-radius: 1rem;
    border: 2px solid black;
    background-color: whitesmoke;
    color: black;
    padding: 10px;
}
