.booking .card{
    width:30%;
    box-shadow: 0px 3px 6px lightgray;
    word-wrap: break-word;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 1%;
    height:fit-content;
}

.booking .card h3{
    margin: 0;
    font-weight: bold;
}

.booking .card h4{
    font-size: 1.2rem;
    color: gray;
    font-weight: bold;
    margin-bottom: 5px;
}

.booking .booking__card__group{
    display: flex;
    flex-wrap: wrap;
}