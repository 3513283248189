.all__events{
    padding-top:5%;
}

.all__events h2{
    font-weight: bold;
    text-align: center;
    margin-top: 2rem;
}

.all__eventsCard{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}

.event__card{
    width:40%;
    margin: 1rem;
    background-color: white;
    box-shadow: 0px 0.234px 8px lightgray;
    border-radius: 5px;
}

.event__card img{
    width: 100%;
}

.event__cardBody{
    padding: 1rem;
}

.event__cardBodyText{
    font-size: 14px;
    color: gray;
}

.event__cardBody h3{
    font-weight: bold;
}

.event__cardFooter{
    padding: 1rem;
    padding-top:0.5rem;
    display: flex;
    justify-content: space-between;
}

.RegisterEventForm{
    margin-top: 65px;
    position: absolute;
    top:0;
    left:0;
    right:0;
}

.registerFormBg{
    background-size: cover;
    background-position: center;
    padding: 1rem;
    position: relative;
    top:0;
    left: 0;
    right:0;
    margin:0;
    margin-bottom:2rem;
    min-height: 60vh;
    width: 100%;
}

.eventForm{
    background-color: white;
    padding: 2rem;
    box-shadow: 0px 0.234px 3px rgb(66, 66, 66);
    border-radius: 5px;
    float: right;
    width: 35%;
    position: fixed;
    top: 100px;
    right: 20px;
    box-sizing: border-box;

}

.eventForm h3{
    font-weight: bold;
    border-bottom: 5px solid rgb(223, 223, 223);
    padding-bottom: 10px;

}

.eventForm__item{
    margin-bottom: 1.2rem;
}

.eventForm__itemLink h4{
    font-size:1.1rem;
    font-weight:bold;
    text-align: center;
    margin-top: 10px;
}

.eventForm__itemLink h4 a{
    margin-left: 5px;
}

.eventForm__item > input{
    padding: 15px;
    font-weight: bold;
    margin-bottom: 1rem;
}

.eventForm__input{
    margin-bottom: 1rem;
    height: 50px;
    font-weight: bold;
}





.eventDetails{
    padding: 2rem;
    padding-top: 0;
    width: 60%;
}

.eventDetails h2{
    font-weight: bold;
}

.eventDetailsPara{
   font-size: 0.9rem;
   margin-bottom: 1rem;
   margin-top: 1rem;
}

.eventDetailsPara span{
    margin-right: 1rem;
}