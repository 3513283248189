.chat-details-header{
    background-color: #006B82;
    padding:3%;

}

.chat-details-header h3{
    color: white;
    font-weight: bold;
    text-align: center;
}

.details-section{
    background-color: #F6F6F6;
    height: 100%;
}

.details-section .profile-pic{
    display: flex;
    justify-content: center;
}


.details-section .profile-pic img{
    width: 15rem;
    
}

.details-section .info-section h5{
    text-align: center;
    font-weight: bold;
}

.details-section .info-section p{
    text-align: center;
}

.details-section .info-section .project-heading{
    background-color: #006B82;
    padding: 1%;
    margin-top: 5%;
}

.details-section .info-section .project-heading h6{
    font-weight: bold;
    color: white;
    text-align: center;
}

.details-section .profile-pic img{
    width: 14vw;
    height: 14vw;
    object-fit: cover;
    box-shadow: 1px 4px 7px rgb(146, 144, 144);
    border-radius: 100px;
    margin: 5%;
}