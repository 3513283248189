.createManualPayment h2{
    font-weight: bold;
}

.createManualPayment .form-control{
    
    width: 35vw;
}

.createManualPayment .form-group{
    margin-right: 5%;
    margin-bottom: 2%;
}

.createManualPayment label{
    font-weight: bold;
    margin-bottom: 2%;
    font-size: 1rem;
}