@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');


:root{
  --primary-color:#006B82;
  --secondary-color:#F27979;
  --dark-primary: #013f4d;
}

.btn-primary{
  background-color: var(--primary-color) !important;
  border: none !important;
}

.btn-primary:hover{
  background-color: var(--dark-primary) !important;;
}

.pay-btn{
  background-color: teal;
  color: white;
  padding: 1% 2%;
  border: none;
  margin:1.5%;
  width: 10vw;
  margin-top: 5%;
  font-size: 1.2rem;
  font-weight: bold;
}

.pay-btn:hover{
  background-color: rgb(25, 44, 44);
}

.pay-btn:disabled{
  background-color: rgb(155, 155, 155);
  color: rgb(119, 119, 119);
  cursor: not-allowed;
}

.pay-color-panel{
  background-color: var(--dark-primary);
  height: 100vh;
  width: 40vw;
  margin-right: 4%;
  padding: 3%;
}

.pay-color-panel h5{
  color: white;
  font-weight: bold;
  margin-top: 1%;
  font-size: 1.5rem;
  margin-left: 30%;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(243, 254, 255) !important;
  overflow-x: hidden;
  
}

.link-btn{
  color: #1890FF;
}

#create-link{
  color: black;
}

#blue-link-text{
  color: white;

}



#create-link:hover{
  color: #1890FF;
}

.link-btn{
  color: white !important;
}

.back-blue{
  /* background-color: #006B82 !important; */
  background: var(--primary-color);

}

.app{
  height: 100vh;
  padding-bottom: 20%;
}

.site-layout{
  overflow-x: auto;
}


 .yellow {
  color: rgb(255, 187, 0);

}

 .green {
  color: rgb(0, 199, 0);
  
}

.red {
  color: red;
  
}

.youtube-card-group{
  display:flex;
  flex-wrap: wrap;
}

.youtube-card{
  width:30%;
}


.youtube-card p{
  color:gray;
}


.youtube-card h4{
  font-size:1.1rem;
  font-weight:bold;
}




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width:1100px){
  body{
    overflow: auto;
  }
}

.primaryBtn{
  background:#F27979;
  color: white;
  border: none;
  text-decoration: none;
  font-weight: bold;
  padding: 5px 20px;
  margin: 10px;
  border-radius: 5px;
}

.primaryBtn:hover{
  background-color: #e26e6e;
  color: white;
}

.primaryBtn:disabled{
  background-color: lightgray;
  color: gray;
  cursor: not-allowed;
}

