.edit-home form{
margin-top: 3%;

}

.edit-home form label{
    font-size: large;
    font-weight: bold;
    margin-bottom: 1%;
}

.edit-home form .form-group{
    margin-top: 3%;
}

.edit-home form textarea{
    height: 30vh;
    resize: none;
    width: 50vw;
}

.edit-home .edit-home-btn{
   background-color: #006B82;
   color: white;
   padding: 1rem;
   font-weight: bold;
   border: none;
   margin-top: 3%;
}

.edit-home-btn:hover{
    background-color: #003844;
}

