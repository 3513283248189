
.feedback{
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    
}

#orderId{
    font-weight: bold;
}

p{
    color: rgb(32, 32, 32);
    font-style: italic;
}
.rating-background{
    background-color: white;
    width: 40% !important;
    padding: 1%;
    border-radius: 5px;
}



.review{
    margin-top: 3rem;
    
}
.review-box{
    display: flex;
    flex-direction: row;
    width: 80%;
    
    
}
.review-box div:first-child{
    width: 50%;
    height: 100%;
}
.review-box div:last-child{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    

}



.feedback-area{
    width: 60%;
    padding: 2%;
    height: 10rem;
    margin-top: 2%;
}
@media  screen and (max-width:800px) {
    .textareabox{
        width: 90%;
    }
    .orderdetails{
        flex-direction: column;
    }
    .review-box{
        flex-direction: column;
        width: 100%;
    }
    .review-box div:first-child{
        width: 100%;
    }
    .review-box div:last-child{
        width: 100%;
    }
    .feedback{
        padding: 0;
    }
    .text-area{
        width: 100%;
    }
    
    
}