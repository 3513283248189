.mynotifications .allNotifications{
background-color: white;
box-shadow: 0px 3px 5px rgb(185, 184, 184);
padding:3% 4%;

}

.mynotifications .allNotifications .singleNotification{
    display: flex;
    justify-content: space-between;
    padding: 2% 3%;
    border: 1px solid lightgray;
    margin-bottom: 2%;
    transition: 300ms ease-out;

}

.mynotifications .allNotifications .deleteNotification{
    font-size: 1.5rem;
    margin-left: 2rem;
}

.mynotifications .allNotifications .notify{
    color:rgb(0, 102, 255);
    font-size: 1.5rem;
    
}

.mynotifications .allNotifications .notificationMsg{
    margin-left: 15px;
}

.mynotifications .allNotifications .no-notification{
    width: 7rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.mynotifications .allNotifications .no-notification-wrapper{
   width: 100%;
}

.mynotifications .allNotifications .no-notification-text{
    font-weight: bold;
    color: gray;
    text-align: center;
    margin-top: 2%;

}

.mynotifications .allNotifications .deleteNotification:hover{
    color:rgb(0, 102, 255);
    cursor:pointer;
}

.mynotifications .allNotifications .gray{
    color: gray;
}

.mynotifications .notification-top{
    display: flex;
    justify-content: space-between;
}

.mynotifications .grayText{
    font-weight: bold;
    color: rgb(134, 133, 133);
}

.mynotifications .boldText{
    color: black;
    font-weight: bold;
}

.mynotifications .blueText{
    color:rgb(0, 102, 255);
    font-size: 1.5rem;
    margin-left: 2rem;
}

.mynotifications .notifyGray{
    color:gray;
    font-size: 1.5rem;
}

