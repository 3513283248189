

.publicPayment .greenColorPallete{
    background-color: #006B82;
    width: 55vw;
    padding: 5% 8%;
    margin-left: -5%;
    height: 150vh;
    display: flex;
    
}

.publicPayment .paymentItems{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.publicPayment .greenColorPallete h4{
    color: white;
    font-family: 'Lato', sans-serif;
    text-align: left;
    font-weight: 700;
    font-size: 2rem;
}

.publicPayment .greenColorPallete .termsAndCondition{
    width:100%;
}

.publicPayment .terms-link{
    color: lightcoral;
    text-decoration: underline;
}

.publicPayment .terms-link:hover{
    color: white;
    
}

.publicPayment .contact-details-section{
    display: flex;
    flex-direction: column;
}

.publicPayment .contact-details-section div{
    margin-right: 2%;
    width: 50%;
}

.publicPayment .contact-item{
    color:white;
    margin-top:4%;
    display:flex;
    width:100%;
    justify-content:space-between;
}

.form-section{
    padding-top: 8%;
    padding-left: 5%;
}
.form-section .form-control{
    font-family: 'Lato', sans-serif;
    width: 35vw;
    box-shadow: 0px 1px 3px lightgray;
}

.form-section .form-group{
    margin-bottom: 3%;
    margin-right: 5%;
}

.form-section label{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    margin-bottom: 2%;
}

.payment-logo-area{
    margin-left: 15vw;
}
.coderartsLogo {
    width: 250px;
    height: 250px;

}

.publicPayment .payment-heading{
    display: none;
}

.publicPayment #country-code-link{
    margin-left: 60%;
    text-align: right;
}


@media screen and (max-width:600px){
    .publicPayment .d-flex{
        display: flex;
        width: 100vw;
        flex-wrap: wrap;
        flex-direction: column;
       
    }

    .publicPayment .payment-heading{
        display: block;
        color: rgb(0, 63, 63);
        font-family: 'Lato', sans-serif;
        text-align: left;
        font-weight: 700;
        font-size: 1.8rem;
        margin-left: -3%;
        border-bottom: 4px solid rgb(0, 63, 63);
        width: 86vw;
        padding-bottom: 3%;
    }

    .publicPayment .green-heading{
        display: none;
    }

    

    .publicPayment .paymentItems {
        display: flex;
        flex-direction: column;
    }

    .publicPayment .paymentItems div:first-child{
        order: 1;
    }

    .publicPayment .form-group{
        width: 100vw;
        margin-left: -3%;
    }

    .publicPayment .termsAndCondition{
        max-width: 90%;
    }

    .publicPayment .payment-logo-area{
        margin-left: 1%;
        margin-top: 2%;
        margin-bottom: 5%;
    }

    .form-section .form-control,.form-section .form-control[placeholder="address"]{
        width: 86vw !important;
    }

    .publicPayment .StripeElement{
        width: 86vw;
        
    }

    .publicPayment #country-code-link{
        margin-left: 25%;
    }

    .publicPayment .pay-btn{
        margin-left: 25%;
        margin-bottom: 10%;
    }
    .publicPayment .greenColorPallete div{
        width: 100vw;
        
    }

   
   
    .publicPayment .greenColorPallete{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 105vw;
    }
}




