.myResumeWrapper{
    padding-left: 2%;
}

.formGroup{
    display: flex;
    flex-wrap: wrap;
    gap:1rem;
    margin-bottom: 1rem;
    align-items:center;
}
.formItem{
    width:20rem;
    
}


.resumeCardGroup{
    display:flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.resumeCardWrapper{
    display:flex;
    flex-wrap: wrap;
    width:80%;
}

.addBtn{
    background: white;
    box-shadow: 0px 3px 5px rgba(116, 115, 115, 0.295);
    padding: 1rem;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width:10rem;
    height:10rem;
    border-radius:5px;
}

.addBtn:hover{
    transition: 0.3s;
    border: 1px solid rgb(0, 119, 255);
}

.addBtn__icon{
    font-size: 4rem;
    color: rgb(0, 119, 255);
    border-radius: 5px;
}

.resumeCard{
    background: white;
    box-shadow: 0px 3px 5px rgba(116, 115, 115, 0.295);
    padding: 1rem;
    width: 30%;
    text-align:center;
    margin: 1rem;
    margin-top: 0;
    border-radius:5px;

}

.myResumeHeader{
    display:flex;
    justify-content:space-between;
    align-items: center;
}



.resumeCard h4{
    font-size: 1.2rem;
    font-weight: bold;
}

.resumeCardIconGroup{
    display: flex;
}

.resumeCardIcon{
    color:rgb(0, 119, 255);
    font-size:1.2rem;
    margin: 5px;
  
}

.resumeCardIcon:hover{
   opacity: 0.8;
}

.resumeCard small{
    color: gray;
}


.resumeCollapse{
    padding:1rem;
    border:1px solid rgb(182, 180, 180);
    border-radius: 10px;
    background: white;
    width:80%;

}

.resumeCollapseHeader{
    display: flex;
    justify-content: space-between;
    
    
}


.resumeCollapseHeader h3{
    font-weight: bold;
}

.resumeCollapseAddBtn{
    width:2rem;
    height:2rem;
    border: 1px solid rgba(116, 115, 115, 0.295);
    color: black;
    padding: 5px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    margin: 5px;
}

.resumeCollapseAddBtn:hover{
    background-color: whitesmoke;
    cursor: pointer;
}

.resumeCollapseAddBtnIcon{
    font-size: 1rem;
}

.resumeCollapseDetailsWrapper{
    margin-top: 0;
}

.resumeCollapseDetails{
    margin: 1.5rem 0;
    padding: 1rem;
    border: 1px solid rgb(182, 180, 180);
    border-radius: 10px;


}

.resumeCollapseDetails__header{
    display: flex;
    justify-content: space-between;
}

.resumeCollapseDetails__header h5{
    font-weight: bold;
}

.resumeCollapseDetails__body{
    margin-top: 10px;
}

.resumeCollapseDetails__body small{
    color: gray;
}


.resumeCollapseDetails__body p{
    font-style: normal;
}



