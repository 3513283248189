.DashboardHome{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

.DashboardHome .dashboard-card{
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 3px 6px lightgray;
    padding: 2% 3%;
    width: 50vw;
    margin-left: 3%;
}

.DashboardHome .dashboard-card .Stats{
display: flex;
justify-content: space-around;
flex-wrap: wrap;
}

.DashboardHome .recentOrder{
    margin-top: 3%;
}

.DashboardHome .recentOrder h4{
    font-size: 1rem;
    font-weight: bold;
}

.DashboardHome .dashboard-card .stat-card{
    width: 12vw;
    display: flex;
    height: 27vh;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 1px solid lightgray;
    border-radius: 5px;
    transition: 200ms ease-in-out;
}

.DashboardHome .dashboard-card .stat-card:hover{
    background-color: #40A9FF;
    border-color: #40A9FF;
    color: white;
    cursor: pointer;
}

.DashboardHome .dashboard-card .stat-card .stat-icon{
    font-size: 2rem;
}

.DashboardHome .dashboard-card .stat-card .cart-total{
    margin-top: 6%;
    font-weight: bold;
}

.DashboardHome .dashboard-card .stat-card .total-label{
    font-size: 16px;
}

.DashboardHome .dashboard-card .stat-card .dashboard-card-total{
    background-color: #40A9FF;
    color: white;
    border-radius: 100px;
    margin-top: 7px;
}


/* .DashboardHome .dashboard-card .stat-card:hover  .dashboard-card-total{
    background-color: #40A9FF;

} */




.DashboardHome .user-info-card{
    width: 20vw;
    margin-right: 3%;
    box-shadow: 0px 3px 6px lightgray;
    background-color: white;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
}


.DashboardHome .user-info-card .dashboard-user-img{
    width: 18vw;

}
.DashboardHome .user-info-card .dashboardUserType{
    background-color: rgb(10, 106, 231);
    color: white;
    font-weight: bold;
    padding: 3px 17px;
    border-radius: 100px;
    margin-top: 2%;
}

.dashboard__main  .recommendText{
    font-size: 1.5rem;
    font-weight: bold;
    color:rgb(61, 60, 60);
}

.dashboard__main .service-card-wrapper .service-card{
    margin-left: 0;
}

.dashboard__main  .bookBtn{
    background-color: #006B82;
    color: white;
    transition: 150ms ease-in;
    border: none;
    padding: 4px 8px;
    font-weight: bold;
}

.dashboard__main .bookBtn:hover{
    background-color: #003a47;
    color: white;
    cursor: pointer;
}




.bookingCardsUser{
    background-color: white;
    padding:5% 3%;
    box-shadow: 0px 4px 6px lightgray;
    display: flex;
    margin-bottom: 2%;
    margin-left:3%;
    border-radius: 5px;

}

.bookingCardsUser .singleBookingCard{
    box-shadow: 0px 3px 5px rgb(231, 231, 231);
    margin-left: 5%;
    margin-right:5%;
    padding:8px 10px;
    width: 200px;
    height: 170px;
    text-align: center;
    vertical-align:middle;
}



.bookingCardsUser .singleBookingCard img{
    height:60px;
   margin-top: 10%;

}

.bookingCardsUser .singleBookingCard .dashboard-user-img{
    height: 80px;
}

.bookingCardsUser .singleBookingCard h4{
    font-size: 1.1rem;
    color: rgb(1, 76, 82);
    margin-bottom: 0;
    font-weight: bold;
}

 .bookingCardsUser .user-info-card .dashboardUserType{
    background-color: rgb(10, 106, 231);
    color: white;
    font-weight: bold;
    padding: 3px 17px;
    border-radius: 100px;
    margin-top: 7%;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}


.bookingCardsUser  .singleBookingCard:hover{
    box-shadow: 0px 7px 12px lightgray;
    cursor: pointer;
}

.bookingCardsUser  .singleBookingCard small{
    color:rgb(134, 131, 131);
}