@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&display=swap');


:root{
    --primary-color:#006B82;
    --secondary-color:#F27979;
  }
  
  .landingPage #link-hover{
      color: white;
      font-weight: bold;
      font-size: 1.2rem;
      text-decoration: underline;
  }
.landingPage #link-hover:hover{
    color: #F27979;
}
.mt-10{
    margin-top: 10%;
}



.heading , .sub {
    max-width: 80%;
    text-align: left;
    
}

.heading{
    color: white;
    margin-top: -2%;
}

.sub p{
    color: rgb(231, 228, 228) !important;
}



.btn-blue{
    border: 2px solid var(--secondary-color);
    padding: 20px;
    color: white;
    width: 300px;
    font-weight: bold;
    text-decoration: none;
    
    
}

.btn-blue:hover{
    color: white;
    text-decoration: none;
    border: none;
    background-color: var(--secondary-color);
   
}

.btn-fill{
    padding: 4%;
    color: white;
    width: 20rem;
    font-weight: bold;
    text-decoration: none;
    border: none;
    background-color:var(--secondary-color);

}

.btn-fill:hover{
    border: 2px solid var(--secondary-color);
    font-weight: bold;
    text-decoration: none;
    color: white;
    background: transparent;

}


.img-section{
    position: relative;
}

.front{
    width: 500px;
    height: 100%;
}

.btn-section{
    margin-top: 7%;
}

.landingPage{
    padding: 0px;
}

.ourServices{
    background-color: white;
    padding: 4% 6%;
    width: 100%;
    margin-top: 0%;
    width: 100vw;
    margin-left: -2%;


}

.ourServices .service-heading{
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600;

}

.ourServices .service-icon{
    width:10vw;
}

.ourServices .service-card{
    height: 65vh;
    margin: 3%;
    width: 25vw;
    box-shadow: 0px 4px 10px lightgray;
}

.ourServices .service-card h5{
    font-family: 'Josefin Sans', sans-serif;

}

.landingPage .read-more-btn{
    width: fit-content;
    background-color: transparent;
    border: 2px solid #006B82;
    color: #006B82;
    font-weight: bold;
    padding: 2% 4%;
    margin-top: 5%;
    transition: 250ms ease-in-out;
}

.landingPage .read-more-btn:hover{
    background-color: #006B82;
    color: white;
    cursor:pointer;
}

.ourServices .service-desc{
    margin-top: 5%;
    text-align: justify;
}

.ourServices h3{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.4rem;
    color: rgb(20, 20, 20);
}

.ourServices .servicePara{
    font-size: 1rem;
    text-align: center;
    margin-top: -1%;
    margin-bottom: 4%;
}

.hireUs{
    background-color: white;
    padding: 3% 2%;
    display: flex;
    border-top: 1px solid rgb(240, 239, 239);
    width: 100vw;
    margin-left: -2%;
}

.hireUs .hireUsData{
    max-width: 40vw;
    margin-left: 5%;
    margin-top: 5%;
    text-align: justify;
}

.hireUs .hireUsData h1,.classRoom .classRoomData h1,.bookSession h1,.mentorship .mentorShipData h2{
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600;
}

.hireUs .developerImg{
    width: 40vw;
}

.hireUs .hireUsPara,.classRoom .classRoomData{
    font-size: 1rem;
    text-align: justify;

}



.classRoom{
    background-color: white;
    padding: 3% 5%;
    padding-bottom: 12%;
    width: 100vw;
    margin-left: -2%;
}

.classRoom .classRoomImg img{
    width: 100%;
}

.classRoom .classRoomData{
    margin-top: 4%;
    text-align: center;
}


.bookSession{
    padding: 3% 5%;
    background-color: #004555;
    color: white;
    text-align: center;
    width: 100vw;
    margin-left: -2%;
}

.bookSession h1{
    color: white;
}

.bookSession .bookSessionPara{
    max-width: 40vw;
    font-size: 1rem;
    text-align: center;
    margin-top: -1%;
}

.mentorship,.ourSessions{
    background-color: white;
    padding: 4% 5%;
    width:100vw;
    margin-left: -2%;
}

.mentorship .mentorShipData{
    text-align: center;
}

.mentorship  .mentorShipPara{
    font-size: 1rem;
    margin-top: -1%;
    margin-bottom: 5%;
    max-width: 40vw;
}

.mentorShipCardGroup,.ourSessions .ourSessionsCards{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.mentorShipCardGroup .mentorship-card{
    width: 20vw;
    text-align: center;
    margin-left: 1vw;
    margin-right: 1vw;
}
.mentorShipCardGroup .mentorship-card h5{
    margin-top: 10%;
   font-family: 'Josefin Sans', sans-serif;
}

.mentorShipCardGroup .mentorship-card .mentorshipParaSmall{
    margin-top: -2%;
}

.mentorShipCardGroup .mentorship-card .mentorship-icon{
    width: 10vw;
}

.ourSessions .session-card{
    width:25vw;
    margin-left: 1vw;
    margin-right: 1vw;
    margin-bottom: 2rem;
    box-shadow: 0px 2px 8px rgb(233, 233, 233);

}

.ourSessions .session-desc h5{
    font-family:'Josefin Sans', sans-serif;
    margin-top: 1%;
}

.ourSessions .session-card-bottom{
    display: flex;
    justify-content: space-between;
}

.ourSessions .session-card-bottom button{
    width: fit-content;
}

.ourSessions .session-card-bottom .session-bottom-item{
    display: flex;
    align-items: center;
    font-size: 1rem;
    width: 30%;
}

.ourSessions .session-card-bottom .session-bottom-item span{
    margin-left: 5%;
}

.ourSessions .ourSessionHeading h2{
    font-family:'Josefin Sans', sans-serif;
}

.ourSessions .sessionPara{
    max-width: 80%;
    margin-top: -2%;
    margin-bottom: 1%;
}

.ourSessions .session-card-icon{
    width: 100%;
}

.ourSessions .session-card-icon:hover{
    transform: scale(1.1);
    cursor: pointer;
    transition: 200ms ease-in;
}

.ourSessions .read-more-btn{
    background-color: #006B82;
    color: white;
    transition: 150ms ease-in;
}

.ourSessions .read-more-btn:hover{
    background-color: #003a47;
    color: white;
    border: 2px solid #003a47;
    cursor: pointer;
}

.contactForm{
    width: 100vw;
    margin-left: -2%;
    padding: 0px;
    padding-top:8%;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
}


.contactForm .contactFormLeft{
    width: 50%;
    background-color: #006B82;
    padding: 2% 6%;
    margin-left: -2%;
    height: 100vh;
}


.contactForm .contactFormLeft h2{
    color: white;
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
}

.contactForm .contactFormLeft .contactFormArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contactForm .contactFormLeft .contactFormArea input{
    border:none;
    border-bottom: 2px solid white;
    display: block;
    background-color: transparent;
    margin-bottom: 6%;
    width: 30vw;
    color: white;
    padding:2% 3%;
}

.contactForm .contactFormLeft .contactFormArea textarea{
    background-color: transparent;
    border:2px solid white;
    color: white;
    width: 65vh;
    margin-top: 4%;
    height: 25vh;
    resize: none;
    overflow-y: auto;
    padding:4%;
}


.contactForm .contactFormLeft .contactFormArea .contactFormBtn{
    padding: 2% 5%;
    background-color: transparent;
    border: 2px solid white;
    color: white;
    font-weight: bold;
    margin-top: 5%;
    margin-bottom: 3%;
}

.contactForm .contactFormLeft .contactFormArea .contactFormBtn:hover{
    background-color: white;
    color: black;
    transition: 200ms ease-in;

}

.contactForm .contactFormLeft .contactFormArea .contactFormBtn:disabled{
    cursor: not-allowed;
}

.contactForm .contactFormRight .contactFormImg{
width: 50vw;
margin-top: -7%;
}




.contactForm .contactFormLeft .contactFormArea input:focus{
    outline: none;
}

.contactForm .contactFormLeft .contactFormArea input::placeholder{
    color: white;
}


.landingPageFooter{
    background-color: #006B82;
    padding: 7% 6%;
    margin-left: -2%;
    width: 100vw;
    margin-top: -6%;
    height: 100vh;

}

.landingPageFooter .landingFooterLogo{
    width: 10vw;
}

.landingPageFooter .footerContent{
    margin-top: 3%;
    display: flex;
    justify-content: space-between;
}

.landingPageFooter .footerContentArea{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20vw;
    margin-top: 3%;
}

.landingPageFooter .footerContentArea h3{
    color: white;
    font-family: 'Josefin Sans', sans-serif;

} 

.landingPageFooter .footerContentArea a,.landingPageFooter .footerContentArea .footerAddress{
    color: white;
    font-size: 1rem;
    margin-bottom: 4%;
}

.landingPageFooter .footerContentArea a:hover{
    text-decoration: underline;
    font-weight: bold;
}

/* .landingPageFooter .footerContentArea .footerAddress{
    color: white;
    font-size: 1rem;
} */

.landingPageFooter .footerSocialArea{
    display: flex;
    justify-content: center;
    margin-top: 7%;
}

.landingPageFooter .footerSocialArea .footerSocialIcon{
    margin-left: 2%;
    margin-right: 2%;
    background-color: #248297;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
}

.landingPageFooter .footerSocialArea .footerSocialIcon:hover{
    background-color: #02596d;
    transition: 180ms ease-in;
}

.landingPageFooter .footerSocialArea .footerSocialIcon a{
    font-size: 1.5rem;
    color:white;
}


.contactForm .contactFormLeft input.captchaInput{
    width: 17vw;
    margin-left: 5%;
    font-size: 1rem;
    font-weight: bold;
}

 .ant-modal-title{
    font-size: 1.3rem !important;
    font-weight: bold;
}

.landingPage .joinAsBtn,.navbar .joinAsBtn{
    background-color: #F27979;
    color:white;
    font-weight: bold;
    border:none;
    margin-top: 2%;
}

.landingPage .joinAsBtn:hover,.navbar .joinAsBtn:hover{
    background-color: #c76464;
    border:none;
}

@media screen and (max-width:700px) {
    .front{
        width: 80%;
        height: 80%;
        margin-right:10%;
    }
}