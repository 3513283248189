.invoiceMaker label {
    font-weight: bold;
    margin-bottom: 2%;
}
.invoiceMaker textarea{
    resize: none;
}
.invoiceMaker input.form-control{
    width: 35vw;
}

.invoiceMaker .order-total{
    background-color: rgb(221, 221, 221);
    padding: 2% 4%;
    width: 35vw;
    
}

.invoiceMaker table{
    width: 100%;
}

.invoiceMaker table td{
    padding-bottom: 2%;
} 


.invoiceMaker .summaryTable{
    background-color: white;
    margin-bottom: 5%;
    box-shadow: 3px 4px 7px rgb(218, 218, 218);
}

.invoiceMaker .summaryTable th{
    border-bottom: 1px solid rgb(226, 224, 224);
    padding: 1%;

}
.invoiceMaker .summaryTable td{
    padding:1%;
}

.invoiceMaker .summaryTable tr:nth-child(even){
    background-color: whitesmoke;
}

/* .invoiceMaker .summaryTable tr:first-child{
    background-color: white;
} */



