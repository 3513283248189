.createOrderForUser{
    margin-left: 1%;
    
}


.createOrderForUser .form-inline{
    display: flex;
    margin-bottom: 3%;
}

.createOrderForUser .form-group{
    width: 100%;
    margin-bottom: 3%;
}


.createOrderForUser .uploadBtn{
    background-color: teal;
    padding: 5px 10px;
    border: none;
    color:white;
    margin: 5px;
    
}

.createOrderForm .uploadBtn:disabled{
    background-color: rgb(158, 158, 158);
    color: rgb(46, 45, 45);
    cursor: not-allowed;

}

/* .createOrderForUser .form-group input,.createOrderForUser .form-group select{
    margin-left: 5%;
    
} */


.createOrderForUser .form-group input:not(#other){
    width: 35vw;
}






.createOrderForUser .form-group select{
    width: 26vw;
} 

.createOrderForUser label{
    font-weight: bold;
    margin-bottom: 2%;
    width: 25vw;

}




.createOrderForUser .btn{
    width: 15rem;
}

.createOrderForUser textarea{
    height: 35vh;
}

.createOrderForUser button:disabled{
    border: none;
    cursor: not-allowed !important;
}

.createOrderForUser input.file{
    width: 50% !important;
} 