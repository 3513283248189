.SendMailToUser{
    padding: 2% 4%;
    background: white;
}

.SendMailToUser .form-group{
    margin-bottom: 3%;
}

.SendMailToUser .form-control{
    width: 40%;
    border-radius: 0px;
}

.SendMailToUser  .write-section{
    border: 1px solid lightgray;
    padding-bottom: 2%;
    width: 75%;
}

.SendMailToUser  .writeBox{
    width: 90%;
    margin-bottom: 2%;
    height: 40vh;
    resize: none;
    padding: 2% 3%;
    border: none;
}

.SendMailToUser textarea:focus{
    outline: none;
}

.SendMailToUser .form-control-select{
    width:40%;
}


.SendMailToUser  .email-send-btn{
    border-radius: 100px;
    margin-left: 2%;
    margin-right: 2%;
    width: 10vw;
    
}

.SendMailToUser  .email-send-btn:disabled{
    background: rgb(155, 155, 155) !important;
    color: rgb(94, 92, 92) !important;
    font-weight: bold !important;
    cursor: not-allowed !important;
}

.SendMailToUser .attachment-icon .fa-paperclip{
    font-size: 1.8rem;

}
.SendMailToUser .attachment-icon:hover{
    cursor: pointer;
}

.SendMailToUser .attached-file-name{
    padding: 1% 2%;
    background: rgb(190, 190, 190);
    color: black;
    font-family: 'Lato' arial;
    font-weight: bold;
    margin-left: 2%;
    width: fit-content;
    display: flex;
    justify-content: space-between;

}

.SendMailToUser .attached-file-name b{
    width: 100%;
}

.SendMailToUser .attached-file-name .fa-times{
    color: red;
    font-size: 1.6rem;
    
}

.SendMailToUser .attached-file-name .fa-times:hover{
    color: darkred;
    cursor: pointer;
}

