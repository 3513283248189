.profile .btn{
    width: 10rem;
}

.profile .btn:hover{
    background: #1890FF;
    border: none;
}

.profile .profileHeaderBadge{
    display: flex;
    align-items: center;
    width:100%;
}

.profile .subscriptionTypeBadge{
    background-color: #007cf0ee;
    color: white;
    font-weight: bold;
    font-size: 0.8rem;
    width: 10vw;
    text-align: center;
    margin-left: 1%;
    margin-top: -1%;
    border-radius: 100px;
    box-shadow: 0px 3px 5px lightgray;
    padding:5px 10px;

}

 .badge{
    background-color: #1890FF;
}

.profile #closeIcon{
    float: right;
    margin: 1%;
    margin-right: -3%;
    font-size: 1.5rem;
    margin-top: -6%;
    color: red;
}

.profile #closeIcon:hover{
    cursor: pointer;
    color:#1890FF;
}

.profile .badge-info{
    background-color: rgb(185, 43, 43);
}

.profile .profileBtnGroup{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    margin-right: 5%;
}

label{
    font-weight: bold;
    font-size: 1rem;
    color: rgb(49, 49, 49);
}