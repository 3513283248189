.file-upload-btn{
    width:15vw;
    height:fit-content;
    padding:0.7rem 2rem;
    border-radius:2rem;
    font-family:arial;
    display:flex;
    font-size:1.3rem;
    text-align:center;
    margin-bottom:2%;
    float:right;
    border:1px solid #006B82;
    color: #006B82;
    align-items:center;
    transition: all 0.3s ease-out;
    
}


.file-upload-btn:hover{
    background:#006B82;
    color:white;
    border: 1px solid transparent;
}