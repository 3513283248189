.singleUser .form-inline{
    display: flex;
    margin-bottom: 3%;
}

.singleUser .form-group{
    margin-right: 3%;
}

.singleUser label{
    font-weight: bold;
    margin-bottom: 2%;
}

.singleUser .form-group input,.singleUser .form-group select{
    width: 35vw;
}

.singleUser .form-group input#username::placeholder{
    color: rgb(255, 40, 40);
}

.singleUser textarea{
    width: 75vw;
    height: 30vh;
    resize: none;
    margin-bottom: 3%;
    
}