.forgetPassword input{
    width: fit-content;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
}

.forgetPassword .card{
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    margin-top: auto;
}

.forgetPassword .form-group{
    margin-left: auto;
    margin-right: auto;
}

.forgetPassword h1{
    margin-top: 7%;
    margin-bottom: 2%;
    font-size: 1.5rem;
    text-align: center;
    font-weight: bold;
}

.forgetPassword .btn-primary{
    height: 40px;
}
.forgetPassword .btn-primary:disabled{
    background-color: rgb(194, 193, 193) !important;
    color: rgb(97, 96, 96);
    cursor: not-allowed;
}

.forgetPassword .error{
    text-align: center;
    margin-top: -20px;
    margin-bottom:20px;
    padding-top: 0;
}
.forgetPassword .error small{
    color:red;

   
}
.forgetPassword .errorField{
    border:1px solid red;
    color:red;
}