.updateForm .card{
    padding: 5%;
    height: 25rem;
}




.updateForm label{
    margin-bottom: 2%;
    font-weight: bold;
}
.updateForm .form-control{
    margin-bottom: 10%;
    width: fit-content;

}

.updateForm #contact{
    width:25rem ;
}

.updateForm .form-group{
    margin: 1%;
}

.updateForm .form-inline{
    display: flex;
    margin-left: -3%;

}


.updateForm .textarea{
    width: 100%;
    resize: none;
    border: 1px solid rgb(204, 203, 203);
    border-radius: 5px;
    padding: 2%;
    margin-left: 2%;
    margin-top: -4%;
    height: 100%;

}


.updateForm .btn {
    margin-top: 5%;
    margin-left: 30%;
}