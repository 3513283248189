.conversation {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin-top: 20px;
  
}


.notification-msg{
  margin-left: 5%;
  width:100%;
  color: rgb(139, 139, 139);
}

.badge-message{
  background-color: red;
  color: whitesmoke;
  margin-left: -10%;
  margin-right: 10%;
}

.highlight{
  background-color:lightblue;
}

.conversation:hover {
  background-color: rgb(245, 243, 243);
}

.conversationImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.conversationName {
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .conversationName {
    display: none;
  }
}
