.messenger {
  height: calc(100vh - 70px);
  display: flex;
  margin-top: 5%;
}

.chatMenu {
  flex: 3;
  border-right: 1px solid rgb(206, 204, 204);
  overflow-y: auto;
}

.chatMenuInput {
  width: 90%;
  padding: 2% 3%;
  border: none;
  padding-left: 7%;
  border-radius: 20px;
  box-shadow: 0px 2px 5px rgb(206, 206, 206);
}
.chatMenuInput::placeholder{
  font-size: small;
  
}

.searchBtn{
  border-radius: 0 !important;
}

.chatBox {
  flex: 5.5;
  padding: 2%;
}

.chatBoxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.chatBoxTop {
  height: 100%;
  overflow-y: scroll;
  padding-right: 10px;
}

.chatBoxBottom {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chatMessageInput {
  width: 80%;
  height: 40px;
  overflow: hidden;
  padding-top: 1%;
  padding-left: 4%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  vertical-align: middle;
  font-size: 1rem;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 50px;
  resize: none;
}

.chatMessageInput:focus{
  outline: none !important;
}

.file-upload-icon{
  font-size: 25px;

}

.file-upload-icon:hover{
  cursor: pointer;
}



.chatSubmitButton {
  width: 70px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid teal;
  color: teal;
  font-weight: bold;
  
}

.chatSubmitButton:hover{
  background-color: teal;
  color: white;
}

.chatOnline {
  flex: 3.5;
  overflow-y: auto;
}

.chatMenuWrapper,
.chatBoxWrapper,
.chatOnlineWrapper {
  padding: 10px;
  height: 100%;
}

.noConversationText {
  position: absolute;
  top: 20%;
  left:26%;
}



.noConversationText img{
  width: 8vw;
  margin-left: 30%;

}

.noConversationText h3{
  font-weight: bold;
  margin-top: 2%;
  text-transform: capitalize;
  text-align: center;
}

 #noText{
  font-weight: bold;
  color: rgb(148, 148, 148);
  max-width: 20vw;
  text-align: center;
  padding-left: 10%;
}

@media screen and (max-width: 768px) {
  .chatMenu {
    flex: 1;
  }

  .chatMenuInput {
    display: none;
  }

  .chatBox{
    flex: 10;
  }

  .chatOnline{
    flex: 1px;
  }
}
