.groupMembers{
    padding: 5px;
    padding-top: 5%;
    background-color: white;
    flex: 1.5;
    
}

.groupMembers .singleMember{
    padding: 2px 3px ;
    margin-bottom: 3%;

}


.groupMembers .singleMember img{
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 100px;
    margin-right: 6%;
    border: 1px solid lightgray;
}