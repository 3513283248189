.bookingDetails .card{
    width: fit-content;
    height: fit-content;
    padding: 2%;
    box-shadow: 0px 3px 6px lightgray;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.bookingDetails table tr td{
    padding: 2%;
}

.bookingDetails .detailsWidth{
    word-wrap: break-word;
    width: 50vw;
}

.singleSubsciption table tr td{
    padding: 0.8%;
}

.bookingDetails table tr:nth-child(even){
    background-color: rgb(243, 243, 243);
}

/* .bookingDetails .card .details-item{
   display: flex;
   font-size: 1rem;
   margin-top: 2%;
   margin-bottom: 2%;
}

.bookingDetails .card .bookingLabel{
    font-weight: bold;
    margin-right: 10%;
} */