.profile-pic-container{
    position: relative;
}
.profile-pic-container .profile-pic{
    width: 8vw;
    height: 8vw;
    object-fit: cover;
    box-shadow: 1px 4px 7px rgb(177, 175, 175);
    border-radius: 100px;

}

.plusBtn{
    border:none !important;
    position: absolute;
    bottom:5%;
    left: 7%;
    z-index: 1;
    font-size: 1.6rem;
    color: whitesmoke;
    background-color: black;
    border-radius: 5px;
    padding: 2px;
}

