.commentArea{
    background-color: white;
    padding: 2% 5%;
    display: flex;
    justify-content: center;

}

.commentArea .chatContainer{
background-color: #E6F7FF;
padding: 2% 3%;
width: 90%;

}

.commentArea .chatContainer .messageArea{
    overflow-y: auto;
    margin-bottom: 5%;
    padding: 2% 3%;
    height: 30vh;
    overflow-y: auto;
    

}






.commentArea .chatContainer .messageArea  .request{
    background-color: #01819e;
    padding: 10px 16px;
    color: white;
    border-radius: 100px;
    font-size: 1rem;
    box-shadow: 0px 2px 6px #94bff7;
    width:fit-content;
    
    
}

.commentArea .chatContainer .messageArea  .commentOwn .fileDownloadLink{
    color: white;

}

.commentArea .chatContainer .messageArea  .commentOther .fileDownloadLink{
    color: black;
    
}


.commentArea .chatContainer .messageArea  .response{
    background-color: #ffffff;
    padding: 10px 16px;
    color: rgb(0, 0, 0);
    border-radius: 100px;
    font-size: 1rem;
    box-shadow: 0px 2px 6px lightgray;
    width:fit-content;

   
}

.commentArea .chatContainer .messageArea  .order1{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    
   
}

.commentArea .chatContainer .messageArea  .order2{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: flex-end;
   
}

.commentArea .chatContainer .messageArea .commentOther{
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    box-shadow: 0px 2px 6px lightgray;
}

.commentArea .chatContainer .messageArea  .commentOwn{
    background-color: #01819e;
    box-shadow: 0px 2px 6px #94bff7;
    color: white;
}




.commentArea .chatContainer .writeArea{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
   
}

.commentArea .chatContainer .writeArea textarea{
    border: 1px solid lightgray;
    resize: none;
    overflow-y: auto;
    width: 75%;
    border-radius: 100px;
    height: 50px;
    padding:5px 25px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1rem;
}

.commentArea .chatContainer .writeArea .comment-btn{
    background-color: teal;
    border: none;
    font-weight: bold;
    color: white;
    padding:1% 3%;
    width: fit-content;
    transition: 0.2s ease-in-out;
}


.commentArea .chatContainer .writeArea .comment-btn:hover{
    background-color: rgb(2, 66, 66);
}

.commentArea .chatContainer .writeArea .comment-btn:disabled{
    background-color: rgb(102, 102, 102);
    font-family: rgb(177, 176, 176);
    font-weight: bold;
    cursor: not-allowed;
}