.events__header{
    display:flex;
    justify-content: space-between;
}

.createEvent__form{
    background-color: white;
    margin-top: 3%;
    box-shadow: 0px 0.234px 6px lightgray;
    padding: 1rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.createEvent__formItem{
   margin: 1rem;
   min-width: 42%;
}


.createEvent__formItem label{
    display: block;
    margin-bottom: 7px;
}

.eventFormGroup{
    display: flex;
    flex-wrap: wrap;
}

.eventDetails__card{
    background-color: white;
    box-shadow: 0px 0.234px 7px lightgray;
    padding: 1rem;
    border-radius: 5px;
}

.eventDetails__card table{
    margin: 2rem;
}

.eventDetails__card table td,.eventDetails__card table th{
    padding: 5px 10px;
    border: 1px solid lightgray;
}

.eventDetails__card table tr:nth-child(even){
    background-color: whitesmoke;
}


.eventCardImg{
    width:100%;
}

.eventCardImg img{
    width: 95%;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 1rem;

}