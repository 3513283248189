.order-card{
    width: 45vw;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    box-shadow: 0px 5px 18px rgb(228, 227, 227);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

}


.qty-input{
    margin-left: 5%;
    float: right;
}

.checkbox {
    display: flex;
    justify-content: space-between;
}

.checkbox label b{
    color: rgb(24, 24, 24);
    font-family: -apple-system, BlinkMacSystemFont, 
    'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1rem;
    
}

.partialCheckbox{
    margin-top: 5%;
}


.summary-box{
    width: 30vw;
    margin-left: 10%;
    border-radius: 5px;
    height: fit-content;
    box-shadow: 0px 5px 18px rgb(228, 227, 227);
}

.summary-box .line-summary{
    display: flex;
    justify-content: space-between;
    
}

.summary-box .checkout-btn{
    background-color: #006B82;
    padding: 2% 4%;
    color: white;
    font-weight: bold;
    border: none;
    font-size: 1rem;
    width: 100%;
    margin-top: 10%;
    transition: 350ms;

}

.summary-box .checkout-btn:hover{
    cursor: pointer;
    background-color: #023844;
}

.summary-box .checkout-btn:disabled{
    cursor: not-allowed;
    background-color: rgb(190, 190, 190);
    color: rgb(116, 116, 116);
}