




.createOrderForm table{
    width: 80vw;
}


.createOrderForn .greenSuccessText{
    color:green;
}

.createOrderForm .uploadBtn{
    background-color: teal;
    padding: 5px 10px;
    border: none;
    color:white;
    margin: 5px;
    
}

.createOrderForm .uploadBtn:disabled{
    background-color: rgb(158, 158, 158);
    color: rgb(46, 45, 45);
    cursor: not-allowed;

}



.createOrderForm table tr td,.createOrderForm table tr th{
    padding: 1%;
    padding-bottom: 3%;
}


.createOrderForm table tr th{
    width: 17vw;
}

.createOrderForm textarea{
    height: 30vh;
    width: 100%;
    resize: none;
    margin-bottom: 3%;
    
}