.singleOrderForm{
    margin-left: 1%;
    
}


.singleOrderForm .form-inline{
    display: flex;
    margin-bottom: 3%;
}

.singleOrderForm .form-group{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 3%;
}

.singleOrderForm .form-group input,.singleOrderForm .form-group select{
    margin-left: 5%;
    
}


.singleOrderForm .form-group input:not(#other){
    width: 30vw;
}






.singleOrderForm .form-group select{
    width: 26vw;

} 

.singleOrderForm label{
    font-weight: bold;
    margin-bottom: 2%;

}




.singleOrderForm .btn{
    width: 15rem;
}

.singleOrderForm button:disabled{
    background-color: lightblue;
    border: none;
    cursor: not-allowed !important;
}

.singleOrderForm input.file{
    width: 50% !important;
}


.singleOrderForm textarea{
    height: 40vh;
}