.assignOrderToEmployee form label{
    font-size: 1.2rem;
    font-weight: bold;
}


.assignOrderToEmployee form  .form-group{
    margin-bottom: 4%;
}


.assignOrderToEmployee form .btn{
    background-color: rgb(13, 71, 71);
    color: white;
    font-weight: bold;
    font-size: 1rem;
    border-radius: 0px;
    border:none;
    padding: 1% 2%;
    

}

.assignOrderToEmployee form .btn:hover{
    background-color: rgb(1, 32, 32);
}