.sessionsForBooking form{
    margin-top: 3%;
    
    }
    
    .sessionsForBooking form label{
        font-size: large;
        font-weight: bold;
        margin-bottom: 1%;
    }
    
    .sessionsForBooking form .form-group{
        margin-top: 3%;
    }
    
    .sessionsForBooking form textarea{
        height: 30vh;
        resize: none;
        width: 50vw;
    }
    
    .sessionsForBooking .sessionsForBooking-btn{
       background-color: #006B82;
       color: white;
       padding: 0.7rem;
       font-weight: bold;
       border: none;
       width: 20vw;
       margin-top: 3%;
    }
    
    .sessionsForBooking-btn:hover{
        background-color: #003844;
    }
    
    