.toDoList__header{
    background-color: white;
    height: 25%;
    padding: 10px 15px;
    margin-top: -50px;
    display: flex;
    justify-content: space-between;
}

.toDoList__header .search{
    border-radius: 100px;
    width: 50%;
    border: 1px solid lightgray;
    padding: 4px 10px;
    display: flex;
    align-items: center;
}

.toDoList__header .search input{
    border: 0px;
    background-color: white;
    margin-left: 10px;
    
}

.toDoList__header .search input:focus{
    outline: none;
    
}




.toDoList__header .search .search-icon{
    font-size:  1rem;
}


.toDoList__header .searchArea{
    width: 80%;
}

.toDoTask .controlArea{
    border-top: 1px solid lightgray;
    background: white;
    padding: 10px;
    box-shadow: 0px 4px 8px rgb(238, 237, 237);
}

.toDoTask .controlArea small.btn-control{
    padding: 5px 10px;
    border-radius: 5px;
}

.toDoTask .controlArea small.btn-control:hover{
    background-color: rgb(247, 247, 247);
    cursor: pointer;
}

.toDoSection__wrapper{
    display:flex;
    flex-wrap: wrap;
    width: fit-content;
    padding:10px 20px;


}

.toDoSection{
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 5px 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    height: 60vh;
    width: 25vw;
    overflow-y: auto;
}

.toDoSection .toDoSection__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toDoSection__header b{
    font-size: 1.2rem;
}

 .sectionIcon{
    margin-left: 5px;
    margin-right: 5px;
    font-size: 1.2rem;
    padding: 5px;
}

.sectionIcon:hover{
    cursor: pointer;
    background:rgb(221, 221, 221);
    border-radius: 5px;

}

.toDoSection .toDoSection__container{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.toDoSection .addTaskBtn{
    background-color: white;
    padding: 5px 20px;
    border: 1px dashed lightgray;
    transition: 200ms all;


}

.toDoSection .addTaskBtn:hover{
background-color: whitesmoke;

}

.toDoList__addNewTask .formGroup{
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.toDoSection .toDoSection__card{
    background-color: white;
    padding: 5px 15px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    border:1px solid lightgray;
}

.toDoSection .toDoSection__card .cardIcon:hover{
    color: rgb(48, 197, 48);
    
}

.toDoSection .toDoSection__card .cardIconComplete{
    color: rgb(48, 197, 48);
}

.toDoSection .toDoSection__card .icon{
    font-size: 1.2rem;
}

.toDoSection .toDoSection__card .toDoSection__cardHeader{
    display:flex;
    justify-content: space-between;
    align-items:center;
}

.toDoSection .toDoSection__card .card__controls{
margin-top: 10px;
margin-bottom: 10px;
display: flex;
align-items: center;
}

.toDoSection .red{
    color: rgb(240, 0, 0);
    font-weight: bold;
    background-color: white;
    padding: 2px 10px;
    border-radius: 8px;


}

.toDoSection .dueDate{
    margin-left: 10px;
}

.toDoSection .gray{
    color: gray;
}

.CompletedTaskBadge{
    background-color: #2FC22F;

}

.PendingTaskBadge{
    background-color: rgb(250, 87, 87);
}

.viewTaskHeading{
    margin-bottom: 0;
    font-weight: bold;
}

.viewTaskDetails{
    color:gray;
    margin-top: 25px;
}

.viewTaskHeader{
    display: flex;
    justify-content: space-between;

}

.viewTaskDeadline{
    color: rgb(88, 87, 87);
}

.viewTaskDeadlineHead{
    font-weight: bold;

}

.viewTaskDeadlineBody{
    background-color: gray;
}

.toDoSection  .redCard{
    background-color: rgb(168, 23, 23) !important;
    border: 1px solid white !important;
    color: white;
}