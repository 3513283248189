.viewOrderDetails{
   display:flex;
   flex-direction: column;
   width:100%;
   background:white;
   border-radius:8px;
   box-shadow:0px 5px 8px lightgray;
   padding:2% 3%

}


.viewOrderDetails .details-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
}


.viewOrderDetails .details-header .title-section{
    flex-basis: 70%;
}

.viewOrderDetails .details-header .progress-section{
    flex-basis: 30%;
}

.viewOrderDetails .details-header .progress-section .progress{
    height: 25px;
    box-shadow: 0px 3px 6px rgb(175, 175, 175);
    background-color: rgb(224, 224, 224);
    
}

.viewOrderDetails .details-header .progress-section .progress .progress-bar{
    padding-top: 10px;
    background-color: #03b36c;
}

.viewOrderDetails .details-header .progress-section .progress h5{
    color: white;
    text-shadow: 0px 3px 5px rgb(121, 121, 121);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
    
}


.viewOrderDetails .details-subsection{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 5%;
    width: 100%;

}

.viewOrderDetails .order-description{
    margin-top: 3%;
}



.viewOrderDetails .order-description,.viewOrderDetails .refund{
    margin-left: 3%;
}

.viewOrderDetails .refund form{
    margin-top: 1%;
}

.viewOrderDetails .order-description #cancellationReason{
color:red;
}

.viewOrderDetails .refund #refundStatus{
    color: rgb(0, 218, 91);
}

.viewOrderDetails .order-description b{
    font-style: normal;
}

.viewOrderDetails .details-subsection .subsection-item{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


.viewOrderDetails .details-subsection .subsection-item div{
    padding:2% 3%;
    display: flex;
    width: 20%;
    justify-content: space-between;
    flex-direction: column;
}


.viewOrderDetails #employeeReporting{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.viewOrderDetails  a.collapse-link{
    display: flex;
    justify-content: space-between;
    padding: 1% 4%;
    border-bottom: 1px solid lightgray;
}

.viewOrderDetails .orderRequirements .card,.viewOrderDetails .deliverables .card,.viewOrderDetails .sourceCode .card {
    background-color: rgb(241, 241, 241);
}

.viewOrderDetails  .uploadArea{
    
    padding:2% 5%;
   
}


.viewOrderDetails .uploadArea img{
    width: 7%;
    margin-bottom: 2%;
}


/* 

.viewOrderDetails table th,.viewOrderDetails table td{
    padding: 1% 2%;
}

.viewOrderDetails table{
    width: 100%;
}
*/

.viewOrderDetails  .red{
    background-color: rgb(209, 53, 53);
    padding: 4px;
    font-weight: bold;
    font-size: 0.8rem;
    border-radius: 100px;
    color: white;
    margin: 0px;
    margin-top: 5px;
    text-align: center;
    width: 15%;
}



.viewOrderDetails  .green{
    background-color: rgb(0, 231, 123);
    padding: 1%;
    font-weight: bold;
    font-size: 0.8rem;
    border-radius: 5px;
    color: rgb(19, 19, 19);
    margin: 2%;
    text-align: center;
    width: 15%;
} 

 .mainViewOrderDetails .msgBadge{
    width: 12px;
    height: 7px;
    padding: 2px 8px;
    background-color: red;
    color: white;
    border-radius: 3px;
}


.viewOrderDetails ul li{
    display: flex;

}

.viewOrderDetails ul li .deleteIcon{
    font-size:1rem;
    color:red;
    margin-left: 20px;
}

.viewOrderDetails ul li .deleteIcon:hover{
    color: rgb(124, 0, 0);
}

.orderDetails__heading__text{
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

 .paginationAreaInOrderDetails{
    margin-top: 15px;
    text-align: center;
}


.paginationAreaInOrderDetails button{
    border: none;
    background-color: transparent;
    color:rgb(0, 140, 255);
    font-size: 17px;
    text-decoration: none;
}

.paginationAreaInOrderDetails button:hover:not(button:disabled){
    text-decoration: underline;
}

.paginationAreaInOrderDetails button:disabled{
    color: gray;
    cursor: not-allowed;
}

.paginationAreaInOrderDetails span{
    color:gray;
    border: 1px solid lightgray;
    padding:5px 8px;
    font-size: 18px;
    margin-left: 8px;
    margin-right: 8px;
}

.proposals__wrapper{
    display:flex;
    justify-content: space-between;
}


.proposals__reviews{
    background-color: white;
    box-shadow: 0px 5px 8px lightgray;
    padding: 1rem;
    border-radius: 10px;
    width:70%;
    margin: 1rem;
}

.proposals__reviews h4{
    text-align: center;
}

.proposals__reviews__container{
    display:flex;
    flex-direction: column;
    align-items: flex-end;
    border:1px solid lightgray;
   height: 45vh;
    background:whitesmoke;
    overflow-y: auto;
}






.proposal__write textarea{
    border: 1px solid lightgray;
    width:90%;
    margin-bottom: 1rem;
    padding: 10px;
    font-weight: bold;
    display: block;

}




.proposals__users{
    background-color: white;
    box-shadow: 0px 5px 8px lightgray;
    padding: 1rem;
    border-radius: 10px;
    width:25%;
    margin: 1rem;
}

.proposals__userItem{
    margin: 10px;
    padding: 10px;
}

.proposals__userItem:hover{
    background-color: whitesmoke;
}

.proposals__userItem img{
    width:50px;
    height: 50px;
    border-radius: 100px;
    object-fit: cover;
    margin-right: 10px;
    margin-bottom: 5px;
}



.proposals__reviews__list{
    margin:10px;

}

.proposals__reviews__msg{
    background:rgb(78, 0, 141);
    padding:10px;
    font-size:0.9rem;
    color:white;
    border-radius:100px;

}

.proposals__review__list__footer{
    margin-top: 5px;
    display: flex;
    align-items: center;
}

.remoteMsgBg{
    background-color: orangered;
}


.leftMsg{
    align-self: flex-start;
}

.proposal__card{
    width:90%;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    background-color: white;
}

.proposal__card__imgWrapper,.reply__proposal__card__imgWrapper{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.proposal__card__imgWrapper img{
    width: 100px;
    height: 100px;
    border-radius: 200px;
    object-fit: cover;
    margin-bottom: 5px;
}

.proposal__cardText{
    width: 70%;
    font-size: 1rem;
    color: rgb(48, 47, 47);
}



.replyTextWrapper span{
    margin:10px;
    font-size:1rem;
    font-weight: bold;
    color:rgb(0, 140, 255);
    cursor:pointer;
}

.proposal__card__Wrapper{
    width:70%;
    height:fit-content;
    margin-bottom:1.2rem;
}

.reply__write__area{
margin: 5%;
}

.reply__write__area textarea{
    width:80%;
    padding:10px;
    border:1px solid lightgray;
}

.reply__write__btns{
    display: flex;
    margin: 2%;
}


.reply__proposal__card{
    margin-left:10%;
    padding: 1rem;
    display: flex;
    width:60%;


}

.reply__proposal__cardText{
    background-color: white;
    padding: 10px;
    width: 100%;

}

.reply__proposal__card__imgWrapper img{
    width: 50px;
    height: 50px;
    border-radius: 200px;
    object-fit: cover;
    margin-bottom: 5px;
}

.replyTextWrapper span:hover{
    color:rgb(3, 94, 168);
}






