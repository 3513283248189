#components-layout-demo-side .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
  
  .site-layout .site-layout-background {
    background: #F0F2F5;
  }

  /* .submenu{
    background-color: var(--dark-primary);
  } */

  /* .ant-menu-item-selected{
    background: var(--secondary-color) !important;
  } */

  