.bookASession .bookingHeader{
    margin-top: 3%;
}

.bookASession .bookingHeader h5{
    font-weight: bold;
    margin-bottom: 0;
}

.bookASession .bookingHeader .bookingDesc{
    width: 60%;
    color: gray;
    margin-top: 5px;
    margin-bottom: 20px;
}

.bookASession .booking__container{
    background-color: white;
    padding: 10px 15px;
    box-shadow: 0px 4px 6px lightgray;
    width: 60%;
}

.bookASession .booking__container .num{
    background-color: rgb(0, 90, 90);
    width: 20px;
    height: 20px;
    text-align: center;
    font-weight: bold;
    border-radius: 100px;
    color: white;
    margin-right: 2%;
}

.bookASession .booking__container .labelGroup{
    display: flex;
    align-items: center;
}

.bookASession .booking__container .formInputs{
    margin-left: 5%;
}

.bookASession .booking__container .form-group{
    margin-bottom: 3%;
}

.bookASession .booking__container .form-control{
    width: 70%;
    margin-top: 2%;
}

.bookASession .w-70{
    width:70% !important;
}

.bookASession .gray{
    color:gray;

}

.bookASession .text-bold{
    font-weight: bold;
}