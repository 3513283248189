.userLeave{
    margin-left:5%;
}

.userLeave form{
    width:50%;
    margin-top: 2%;
   
}

.applyLeave .formGroup{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.applyLeave .formGroup label{
    margin-bottom: 10px;
}

 .myLeave .searchHeader{
    display: flex;
    justify-content: space-between;
}