.fileUploadComponent{
    display: flex;

}

.fileUploadComponent .upload-vector{
    width: 40vw;
    height: auto;
    margin-top: 5%;


}

.fileUploadComponent .upload-icon{
    width: 6rem;
}

.fileUploadComponent .upload-heading{
    margin-top: 5%;
    margin-left: 15%;
    text-align: center;
    font-family: 'Lato';
    font-weight: 600;
}

.fileUploadComponent .form-group{
    margin-left: 8%;
    margin-bottom: 5%;
}

.fileUploadComponent  label{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    margin-bottom: 2%;
}

.fileUploadComponent .form-control{
    font-family: 'Lato', sans-serif;
    width: 40vw;
    box-shadow: 0px 1px 3px lightgray;
}