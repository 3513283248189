.single-payment-details .card:first-child{
    width:100%;
}
/* .single-payment-details .item-container{
    padding: 1.5% 2.5%;
    display: flex;
    flex-wrap: wrap;
    
}

.single-payment-details .item-container div{
    text-align: center;
    margin-left: 4%;
} */

.single-payment-details .successTag{
    background-color: lightgreen;
    padding: 5px 8px;
    font-size: 14px;
    border-radius: 5px;
    margin-left: 1%;
}


.single-payment-details table th,.single-payment-details table td{
    padding: 1% 2%;
}

.single-payment-details table{
    width: 100%;
}



