.userSubscription .subs-header{
    width:100%;
    margin-bottom: 3%;
    margin-top: -3%;
    
}

.userSubscription .subsCardGroup{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

.userSubscription .subsCard{
    width: 25%;
    margin: 1%;
    box-shadow: 0px 5px 8px lightgray;
    border-radius: 5px;
    transition: 0.2s ease-in;
}

.userSubscription .subsCard:hover{
background-color: rgb(247, 245, 245);
}

.userSubscription .subsCard .card-list-details{
    margin-top: 10%;
}

.userSubscription .subsCard  .singleList{
    margin-bottom: 5%;
    display: flex;
}

.userSubscription .subsCard .card-list-details .checkIcon{
    width:15px;
    margin-right: 10px;

}


.userSubscription .subsCard .listData{
    margin-left: 10px;
}



/* .userSubscription .subsCard li::before{
    content:"✔️";
    margin-left: -20px; margin-right: 10px;
} */

.userSubscription .subsCard-price{
    margin-top: 10%;
    text-align: center;
}

.userSubscription .subsCard-price h6{
    font-weight: lighter;
    font-size: 2rem;
}

.userSubscription .perMonth{
    font-size: 1rem;
    font-weight: bold;
    color: rgb(100, 100, 100);
}
.userSubscription .subsCard-btn-area {
    margin-top: 10%;
}


.userSubscription .subsCard-btn-area  .subsCard-btn{
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 80%;
}

.userSubscription .subsCard .subsCard-header{
    text-align: center;
    border-bottom: 3px solid rgb(0, 162, 255);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.userSubscription .subsCard .subsCard-header h5{
    font-weight: bold;
    font-size: 1.7rem;
}

.subscription-input-group{
    display: flex;
    justify-content: space-between;
    margin-top: 4%;

}

.subscription-input-group input{
    margin: 1.5%;
    border-radius: 0px;
    box-shadow: 0px 2px 3px rgb(211, 211, 211);
}

.subscription-payment-form label h6{
    font-weight: bold;
}

.subscription-payment-form .form-group{
    margin-bottom: 5%;
}

.subscription-payment-form textarea{
    border-radius: 0px;
    box-shadow: 0px 2px 3px rgb(211, 211, 211);
    resize: vertical;
}

/* .subscription-payment-form .formGroups{
    display: flex;
    justify-content: space-between;
    flex-direction: row;

}

.subscription-payment-form .form-group,.subscription-payment-form .formGroups{
    margin-bottom: 3%;
}

.subscription-payment-form form label h6{
    font-weight: bold;
}

.subscription-payment-form .formGroups .form-control{
    width: 30vw !important;
} */