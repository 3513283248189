.profile-img-card{
    height: 8rem;
    width: 8rem;
    margin-left: 25%;
    margin-bottom: 2rem;
}

.registerForm .btn:disabled{
    cursor: not-allowed;
    background: gray !important;
    color: rgb(65, 65, 65);
}

.registerForm .card{
    margin-top: 2%;
    margin-left: 35%;
    width: fit-content;
    padding: 5%;
    

}

.registerForm .form-control{
    border-radius: 1.5rem;
    margin-bottom: 0.8rem;
}

.registerForm .btn-primary{
    background-color: var(--primary-color);
    border-radius: 2rem;
    border: none;
    width: 16rem;
    font-weight: bold;

}

