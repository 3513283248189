.mailRecords .composeMailBtn{
    width: fit-content;
    border-radius: 100px;
    height: fit-content;
    border: none;
    background-color: white;
    box-shadow: 0px 3px 5px lightgray;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    margin-right: 8%;
}


.mailRecords #composePlus{
    width: 20px;
    margin-right: 12px;
    margin-top:-3px;
}

.mailRecords .composeMailBtn:hover{
    background-color: rgb(245, 245, 245);
    border: none;

}

.mailForm form input.form-control{
    margin-bottom: 5% !important;
}


.mailForm .attachment-icon:hover{
    cursor: pointer;
}

.mailForm .attached-file-name{
    padding: 1% 2%;
    background: rgb(190, 190, 190);
    color: black;
    font-family: 'Lato' arial;
    font-weight: bold;
    margin-left: 2%;
    width: fit-content;
    display: flex;
    justify-content: space-between;

}

.mailForm .attached-file-name b{
    width: 100%;
}

.mailForm .attached-file-name .fa-times{
    color: red;
    font-size: 1.6rem;
    
}

.mailForm .attached-file-name .fa-times:hover{
    color: darkred;
    cursor: pointer;
}

.mailRecords .composeMailWrapper{
    width: fit-content;
    margin-right: 2%;
    
}

.mailRecords .viewMoreMsg,.allFeedback .viewMoreMsg{
    color: rgb(0, 102, 255);
}

.mailRecords .viewMoreMsg:hover,.allFeedback .viewMoreMsg:hover{
    text-decoration: underline;
    cursor: pointer;
}


