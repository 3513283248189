.navbar{
    display: flex;
    justify-content: space-between;
    margin-top:0;
    margin-bottom: 1.3%;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    padding-top:10px;
    z-index: 100;
}

.navbar .nav-left-items{
    margin-left:3%;
}


.navbar .notificationCountBadge{
    background-color: red;
    color: white;
    border-radius: 2px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 13px;
    
}




.nav-right-items{
    margin-right: 5%;
    display:flex;
    align-items:center;
}

.nav-right-items .notificationIconWrapper{
    padding-top: 5px;
    margin-left: 10px;

}

.nav-right-items .notificationIconWrapper .notificationIcon{
    font-size: 1.2rem;
    color:white;
}

.ant-dropdown .notificationMenu{
    max-width: 500px;
    background-color: white;
    padding: 10px;
    box-shadow: 0px 3px 6px gray;
}

.ant-dropdown .notificationMenu ul{
    list-style-type: none;
    margin: 0;
    padding:0;
}

.ant-dropdown .notificationMenu ul li{
    margin-bottom: 10px;
    color:black;
    display:flex;
    justify-content: space-between;
    
}

.ant-dropdown .notificationMenu .deleteNotification{
    font-size: 1.5rem;
    margin-left: 2rem;
}
.ant-dropdown .notificationMenu .deleteNotification:hover{
    color:red;
    cursor:pointer;
}

.ant-dropdown .notificationMenu .notificationLink{
    width: 100%;
    margin-left: 45%;
}

.ant-dropdown .notificationMenu .notify{
    margin-right: 5px;
    margin-top: 6px;
}


.ant-dropdown .notificationMenu ul li small{
    color:gray;
    display: block;
}


.profile-avatar{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}



.profile-items{
    display: flex;
    width:  3rem;
    
}



.coinArea{
    margin-left: 10px;
    display: flex;
    align-items: center;

}

.coinArea img{
    width:25px;
    margin-right: 10px;

}

.coinArea span{
    font-weight: bold;
    color: white;
}