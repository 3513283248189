.error-img{
    margin-left: 30%;
}

.error-container{
    height: 100vh;
    width: 100vw;
    margin-left: 0;
}

.error-btn{
    background-color: transparent;
    border: 1px solid #006B82;
    color: #006B82;
    border-radius: 50px;
    padding: 1rem;
    margin-left: 45%;
    font-weight: bold;
}

.error-btn:hover{
    background-color: #006B82;
    border: 1px solid transparent;
    color: white;
}