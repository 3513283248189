.whatsappWrapper{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    margin-left: -10px;

    

}

.whatsappWrapper .chatBanner{
    position: static;
}

.whatsappChatAreaWrapper{
    display: flex;
    height: 100%;
    overflow: auto;
}

.whatsappChats{
    width:30%;
    padding: 1rem;
    background-color: white;
    border-right: 1px solid lightgray;
    box-sizing: border-box;
    overflow: auto;
}


.whatsappMsgArea{
    width: 60%;
    background-color: rgb(253, 252, 252);
    border-right: 1px solid lightgray;
    box-sizing: border-box;
}

.whatsappMsgAreaIcon{
    color:white;
    font-size:1.3rem;
    margin:5px;
}

.whatsappControlIcon{
    font-size:2rem;
    margin: 1rem;
}

.whatsappControlIcon:hover{
    color:#1da1f2;


}

.whatsappChat__userWrapper{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content: flex-start;
   
}

.whatsappChat__user{
    display: flex;
    width:100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
   
    padding: 10px 15px;
}

.whatsappChat__user b{
    font-size: 1.2rem;
    font-family: 'Calibri';

}

.whatsappChat__user small{
    color:gray;

}


.whatsappChat__user:hover{
    background-color: whitesmoke;
    cursor: pointer;
}

.activeChatUser{
    background-color: whitesmoke;
}




.whatsappMsgControls{
    width:10%;
    display: flex;
    background-color: white;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.whatsappChatImgWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10%;

}

.whatsappChatBanner{
    background-color:#1da1f2;
    width:100%;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.whatsappChatBanner h4{
    font-size:1.2rem;
    color:white;
    margin:0;
}

.whatsappChatBanner p{
    color:whitesmoke;
    margin:0;
    margin-top: 5px;
}

.whatsappChatImgWrapper img{
    width:15rem;

}

.whatsappChatImgWrapper h4{
    color:gray;
    font-size: 1.5rem;
    font-family: 'Calibri';
    margin-top: 1rem;
}

.whatsappChatBannerWrapper{
    display:flex;
    flex-direction:column;
    height:90%;
    justify-content:space-between;

}

.whatsappSendArea{
    margin: 1rem;
}

.whatsapp__msgArea{
    height:50vh;
    overflow:auto;
    width:100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.whatsapp__chatText{
    width:250px;
    border-radius: 1rem;
    box-shadow: 0px 2px 4px lightgray;
    padding: 10px;
    margin:15px;
    margin-right: 0px;
    margin-bottom: 5px;
    border: 1px solid rgb(121, 118, 118);
    
}

.whatsapp__chatTextReceived{
    width:250px;
    border-radius: 1rem;
    box-shadow: 0px 2px 4px lightgray;
    padding: 10px;
    margin:15px;
    margin-left: 0px;
    margin-bottom: 5px;
   background: rgb(26, 45, 216);
   color:white;

}
.whatsapp__singleChatRight{
    align-self: flex-end;
    margin-bottom: 15px;
}

.whatsapp__singleChatLeft{
    margin-bottom: 15px;
}

.whatsapp__singleChatRight small, .whatsapp__singleChatLeft small{
    color:gray;
}
.whatsapp__chatTemplate{
    width:250px;
    margin:10px;
    margin-left: 0px;
    margin-bottom: 5px;
    border-radius: 1rem;
    border: 2px solid black;
    background-color: whitesmoke;
    color: black;
    padding: 10px;
}