.groupConversation{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    background-color: white;
    
}

.groupConversation .allGroups{
    flex: 2;
    padding: 1%;
    height: 100vh;
    background-color: white;
    margin-left: 0px;
} 

.groupConversation .deleteGroupIcon{
    float: right;
    font-size: 1.2rem;
    cursor: pointer;
}

.groupConversation .deleteGroupIcon:hover{

    cursor: pointer;
    
}

.groupConversation .groupChatBox{
    flex: 4.5;
    padding-top: 5%;
    margin-bottom: -5%;
}


.groupConversation .groupChatBox .groupChatBoxWrapper{
    height: 90%;
    display: flex;
    flex-direction: column;
}

.groupConversation .groupChatBox .groupChatBoxWrapper .groupChatBoxTop{
    padding: 4% 4%;
    background-color: whitesmoke;
    height: 27rem;
    margin-top: -2%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.groupConversation .singleGroupMsg{
    margin-bottom: 3%;

}

/* .groupConversation .singleGroupMsg .msgBottom{
    margin-top: 5%;
    width: 15rem;
} */

.groupConversation .highlight{
    background-color: rgb(240, 240, 240);
}

.groupConversation .groupChatBox .groupChatBoxWrapper .groupChatBoxBottom{
    display: flex;
    padding: 3% 5%;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    
}

.groupConversation .groupChatBox .groupChatBoxWrapper .groupChatBoxBottom .input-box{
    border: 1px solid rgb(180, 180, 180);
    width: 35rem;
    border-radius: 10px;
    height: 2.5rem;
    resize: none;
    overflow-y: auto;
    padding: 1% 2%;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

}



.groupConversation .noConversationText{
    width: 100%;
}



.groupConversation .noConversationText h3{
    margin-left: -30%;
}

.groupConversation .noConversationText #noText{
    margin-left: 20%;
}


.groupConversation .group-header{
    margin-top: 20%;

}

.groupConversation .group-header h3{
    margin-top: 3%;
}

.groupConversation .group-header .groupHeaderControls {
    display: flex;
    justify-content: space-between;
}


.groupConversation .group-header .groupHeaderControls .addGroup{
    margin-top: 4%;
}

.groupConversation .groupSideBody{
    margin-top: 2%;

}

.groupConversation .groupSideBody .singleGroup{
    margin-bottom: 3%;
    padding: 2% 3%;


}

.groupConversation .groupSideBody .singleGroup:hover{
    background-color: rgb(240, 240, 240);
   cursor: pointer;
}

.groupConversation .groupSideBody .singleGroup img.groupIcon{
    width: 40px;
    height:40px;
    border-radius: 100px;
    border: 1px solid lightgray;
    padding:5px;
}

.groupConversation .groupSideBody .singleGroup .groupName{
    margin-left:3%;
    font-size: 1rem;
    font-weight: bold;
}