.createOrderPublic{
    margin-left: 10%;
    margin-right: 10%;
    margin-top:7%;
    
}

.createOrderPublic h3{
    color: black;
}


.createOrderPublic .form-inline{
    display: flex;
    margin-bottom: 1.5%;
}

.createOrderPublic .form-group{
    width: 100%;
    margin-bottom: 1.5%;
}

/* .createOrderPublic .form-group input,.createOrderPublic .form-group select{
    margin-left: 5%;
    
} */


.createOrderPublic .form-group input:not(#other){
    width: 35vw;
}






.createOrderPublic .form-group select{
    width: 26vw;
} 

.createOrderPublic label{
    font-weight: bold;
    margin-bottom: 1%;
    width: 25vw;

}




.createOrderPublic .btn{
    width: 15rem;
}

.createOrderPublic textarea{
    width: 96%;
    height: 35vh;
}

.createOrderPublic button:disabled{
    border: none;
    cursor: not-allowed !important;
}

.createOrderPublic input.file{
    width: 50% !important;
} 


.createOrderPublic #categorySelect{
    width: 35vw;
}

.createOrderPublic .uploadMultipleMsg{
    width: 50%;
}

.createOrderPublic .uploadMultipleMsg p{
    color: rgb(221, 1, 1);
}

.createOrderPublic .requiredStar,.createOrderPublic .error{
    color:red;
}

.createOrderPublic .errorField{
    border: 1px solid red;
}