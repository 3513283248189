@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&family=Nunito&display=swap');
.codersarts-chat{
    font-family: 'Josefin Sans', sans-serif;
}


.codersarts-chat .chatBanner{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: fit-content;
    padding-top: 2%;
    padding-left: 2%;
    padding-right: 2%;
    border-bottom: 1px solid lightgray;
    padding-bottom: 1%;
    width: 100%;
    background-color: white;
    
}
.codersarts-chat .chatBanner .chatLogo{
    display: flex;
    width:20vw;
}

.codersarts-chat .chatBanner .chatLogo img{
    width: 3vw;
}

.codersarts-chat .return-icon{
    width: 2rem;
    margin-top: 1%;

}

.codersarts-chat .return-icon:hover{
    width: 2.2rem;
    cursor: pointer;
}

.codersarts-chat .chatBanner .codersartsChatLogo{
    margin-left: 5%;
    margin-top:1%;
    display:flex;

}

.codersarts-chat .chatBanner .codersartsChatLogo h5{
    font-weight: bold;
    margin-top: 5%;
    
}

.codersarts-chat .searchArea{
    width: 50vw;
    padding: 1%;
    height: 7vh;
    display: flex;
    align-items: center;
    border: none;
    margin-left: 5%;
    background-color: #F1F3F4;
    border-radius: 5px;

}

.codersarts-chat .searchArea input{
    width: 100%;
    border: none;
    background-color: transparent;
    font-family: 'Nunito', sans-serif;
    margin-left: 3%;
}

/* .codersarts-chat .searchArea input{} */

.codersarts-chat .searchArea input:focus{
    outline: none !important;
}

.codersarts-chat  .searchArea:hover{
    background-color: white;
    box-shadow: 0px 2px 4px lightgray;
    box-shadow: 0px 0.7px 2px lightgray;
    transition: 200ms ease;
}

.codersarts-chat .searchArea .searchIcon{
    font-size: 1.1rem;
    margin-left: 2%;
}

.codersarts-chat .profileArea{
    width: 20vw;
    display: flex;
    align-items: center;
    justify-content: right;

}

.codersarts-chat .profileArea img{
    width: 50px;
    height: 50px;
    border-radius: 100px;
    object-fit: cover;
}

.codersarts-chat .profileArea .chatUserInfo{
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2%;
}

.codersarts-chat .profileArea .chatUserBadge{
    background-color: rgb(0, 140, 255);
    font-weight: bold;
    font-size: 0.8rem;
    margin-top: -2%;
    text-transform: lowercase;
    padding: 1px 2px;
    color: white;
    border-radius: 8px;
    box-shadow: 0px 2px 3px lightblue;
    width: 4rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;

}

.codersarts-chat .chatArea{
    display: flex;
    width: 100%;
    background-color: white;
    height: 100vh;
    position: absolute;
    top: 14vh;
    bottom: 0;
    left: 0;
    right: 0;
    
}

.codersarts-chat .chatSideNav{
    height: 100%;
    width: 27%;
    border-right: 1px solid lightgray;
    padding-top: 2%;
}

.codersarts-chat .chatSideNav .chatLists{
    display: flex;
    flex-direction:column;
    height: 27vh;
    overflow: auto;
    background-color: rgb(250, 248, 248);
}

.codersarts-chat .chatSideNav .singleChatList{
    width: 100%;
    display: flex;
    align-items: center;
    padding:2%;
    padding-left: 5%;
    padding-top: 4%;
    padding-bottom: 4%;
}

.codersarts-chat .chatSideNav .singleChatList:hover{
    background-color: #daf9fc;
    cursor: pointer;
}

.codersarts-chat .chatSideNav .chatLists .activeChat{
    background-color: #daf9fc;
}

.codersarts-chat .chatSideNav .chatCategoryHeading{
    margin-left: 5%;
}

.codersarts-chat .chatSideNav .singleChatList img{
    width: 40px;
    height:40px;
    object-fit: cover;
    border-radius: 100px;
}

.codersarts-chat .chatSideNav .goBack{
    margin-top: 5%;
    margin-left: 5%;
}

.codersarts-chat .chatSideNav .singleChatList h5{
   margin-top: 5%;
   margin-left: 5%;
   font-size: 1rem;
}


[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: auto;
  background: white;
  border: 0px;
  border-radius: 0px;

}



.ant-collapse-borderless{
    background-color: white;
}


.ant-collapse-header{
    font-size: 1.2rem;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    font-size: 1.2rem;
}


.codersarts-chat .chatMessagesWriteArea{
    display: flex;
    flex-direction: column;
    width: 60%;

}





.codersarts-chat .chatMessagesWriteArea .groupChatBoxBottom{
    display: flex;
    padding: 3% 5%;
    align-items: center;
    justify-content: space-between;
    background-color: white;

    
}

.codersarts-chat .chatMessagesWriteArea .groupChatBoxBottom .input-box{
    border: 1px solid rgb(180, 180, 180);
    width: 50vw;
    border-radius: 10px;
    height: 2.5rem;
    resize: none;
    overflow-y: auto;
    padding: 1% 2%;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

}


.codersarts-chat .chatMessagesWriteArea .groupChatBoxBottom .msgSubmit{
    width: 8vw;
    margin-left: 10%;
}

.codersarts-chat .chatMessagesWriteArea .groupChatBoxBottom .attachment{
    margin-left: 3%;
    margin-right: 3%;
}


.codersarts-chat .chatMessagesWriteArea .allChatMessages{
    padding-left: 5%;
    padding-right: 5%;
    height: 70%;
    width: 70vw;
    overflow: auto;
    padding-top: 10%;
    margin-top: 1%;
}


.codersarts-chat  .NoConvImage{
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 8%;
}


.codersarts-chat .NoConvImage img{
    width: 15vw;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.codersarts-chat .noMessages{
    text-align: center;
    display: block;
    margin-top: 10%;
    

}


.codersarts-chat .noMessages img{
    width: 10vw;
}

.codersarts-chat .noMessages h5{
    font-size: 1rem;
    color: rgb(170, 170, 170);
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    margin-top: 5%;
}


.codersarts-chat .currentChatBanner{
    width: 70vw;
    height: fit-content;
    position: fixed;
    padding: 1% 2%;
    background-color:#F1F3F4;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 2px lightgray;
    z-index: 100;
}

.codersarts-chat .currentChatBanner img{
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100px;
}


.codersarts-chat .currentChatBanner h6{
    margin-left: 10%;
    width: 100%;
}

.codersarts-chat .currentChatUserBadge{
    background-color: rgb(0, 140, 255);
    font-weight: bold;
    font-size: 0.8rem;
    margin-top: -2%;
    text-transform: lowercase;
    padding: 1px 2px;
    color: white;
    border-radius: 8px;
    box-shadow: 0px 2px 3px lightblue;
    width: 4rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.codersarts-chat .currentChatInfo{
    margin-left: 3%;
}

.codersarts-chat .currentGroupInfo{
    margin-left: 3%;
    text-align: left;
}

.codersarts-chat .groupCreatedAt{
    font-weight: bold;
    color: gray;
}

.codersarts-chat .convInfo{
    margin-left: 5%;
}


.codersarts-chat .deleteChatIcon{
    font-size: 1rem;
    color: red;
    margin-right: 7%;

}

.codersarts-chat .chatNotificationBadge{
background-color: red;
margin-top: 0;
margin-left: -10px;
}

.codersarts-chat .singleChatList .singleChatAllInfo{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.codersarts-chat .homeIcon{
    font-size: 1.2rem;
    color: rgb(48, 47, 47);
}

.codersarts-chat .homeIcon:hover{
    color: #2196F3;
    transition: 180ms ease-in;
}