.message {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.messageTop{
    display: flex;
}

.msgImage{
    box-shadow: 0px 2px 5px rgb(170, 169, 169);
}

.msgImage:hover{
    transform: scale(0.8);
    transition: 180ms ease-in-out;
    filter: brightness(80%);
   
}


.messageImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.otherFileType{
    padding:1.5% 2%;
    width:fit-content;
    display: flex;
    flex-direction: row;
    background:rgb(236, 235, 235);
    color: black;
    font-weight: bold;
    box-shadow: 0px 2px 6px #b4bfc0;
    border-radius: 5px;

}

.otherOwn{
    background-color: #1877F2;
    box-shadow: 0px 2px 6px #94bff7;
    color: white;
}

.otherOwn a{
    color: white !important;
}

.messageText{
    padding: 10px;
    border-radius: 20px;
    background-color: rgb(245, 241, 241);
    color: black;
    max-width: 300px;
    box-shadow: 0px 2px 6px #b4bfc0;
}

.messageBottom{
    font-size: 12px;
    margin-top: 10px;
}

.message.own{
    align-items: flex-end;
}

.message.own .messageText{
    background-color: #1877f2;
    color: white;
    box-shadow: 0px 2px 6px #94bff7;
   
}