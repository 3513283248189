.subscription .subscription-header{
    display: flex;
    justify-content: space-between;
}

.subscription .create-btn{
    width: 20vw;
    font-size: 1rem;
    font-weight: bold;
}

.subscription .subsCardGroup{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


.subscription .subsCardGroup .subsCard{
    width: 25%;
    margin: 1%;
    box-shadow: 0px 5px 8px lightgray;
    border-radius: 5px;
}

.subscription .subsCard .subsCard-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.subscription .subsCard .subsCard-header h5{
    font-size: 2rem;
    font-weight: bold;
}

.subscription .subsCard .subsCard-edit{
    font-size: 1.2rem;
    color: rgb(0, 102, 255);
}


.subscription .subsCard .subsCard-edit:hover{
    color: black;
    cursor: pointer;
}


.subscription .subsCard .subsCard-delete{
    font-size: 1.2rem;
    color: rgb(255, 0, 0);
}


.subscription .subsCard .subsCard-delete:hover{
    color: rgb(117, 1, 1);
    cursor: pointer;
}


.subscription .subsCard .cardActions{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.subscription .subsCard ul{
    list-style-type: none;
    margin-left: -10%;
}

.subscription .subsCard ul li{
    margin-bottom: 3%;
}


.subscription .subsCard-price h6{
    font-weight: bold;
    color: rgb(117, 114, 114);
    margin-top: -2%;
    margin-bottom: 8%;
}
