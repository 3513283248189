.tableProfilePic{
    width :50px;
    height:50px;
    object-fit:cover;
    border-radius: 100px;
    margin-right: 10px;
    margin-left: 10px;
}



.allAttendance .search{
    border-radius: 100px;
    width: 50%;
    border: 1px solid lightgray;
    padding: 7px 10px;
    display: flex;
    align-items: center;
    background-color: white;
    height: fit-content;
}

.allAttendance .search input{
    border: 0px;
    margin-left: 10px;
    background-color: white;
    width: 100%;
    
    
}

.allAttendance .search input:focus{
    outline: none;
    
}


.allAttendance .searchHeader{
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3%;
}

.allAttendance .searchByDate{
    display: flex;
    flex-direction: column;
}

.allAttendance .searchByDate input{
    border: 1px solid lightgray;
    padding: 5px 10px;
}

.allAttendance .tableProfilePicWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tableProfilePicWrapper .badge{
    margin-top: 8px;
}

.allAttendance .yellow {
    color: rgb(255, 187, 0);

}

.allAttendance .green {
    color: rgb(0, 199, 0);
    
}

.allAttendance .red {
    color: red;
    
}