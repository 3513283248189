.profile-img-card{
    height: 8rem;
    width: 8rem;
    margin-left: 25%;
    margin-bottom: 2rem;
}

.loginForm .card{
    margin-top: 15%;
    margin-left: 70%;
    width: fit-content;
    padding: 5%;
    
}
 
.eye-icon{
    margin-top: 2%;
    margin-left: 2%;
}

.eye-icon:hover{
    cursor: pointer;
}

.loginForm .input-password:focus{
outline: none !important;
border: none !important;

}

.loginForm .form-control{
    border-radius: 1.5rem;
    margin-bottom: 1.5rem;
}

.loginForm .btn-primary{
    background-color: var(--primary-color);
    border-radius: 2rem;
    border: none;
    width: 16rem;
    font-weight: bold;

}

.login__footer__link{
    display:flex;
    justify-content:space-between;
    margin-top:1rem;
}

.loginWithMobile{
    margin-top:1rem;
    text-align:center;
}

.loginWithMobile a{
    text-decoration:none;
    text-align:center;
    border:2px solid #006B82;
    padding:5px 20px;
    border-radius:100px;
    color:#006B82;
    font-weight:bold;
}

.loginWithMobile a:hover{
    color:white;
    background:#006B82;
}




